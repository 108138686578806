import classes from "./Pools.module.css";
import { Pool } from "../Pool";
import { INetworkCore } from "../../../../../common/_evm/interfaces/INetworkCore";
import { getFarmPools } from "../../mappers/getFarmPools";
import { useTokenListContext } from "../../../../../contexts/TokenList";

export const Pools = ({ core }: { core: INetworkCore }) => {
  const { tokenList } = useTokenListContext();

  return core.farming ? (
    <div className={classes.Wrapper}>
      {getFarmPools(core.farming, tokenList).map((pool, idx) => (
        <Pool key={idx} pool={pool} />
      ))}
    </div>
  ) : (
    <div />
  );
};
