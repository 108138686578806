import { formatUnits } from "ethers";

export const getShowBalance = (
  balance: bigint | string | undefined,
  decimals?: number,
) => {
  if (balance === undefined) return "-";
  const balanceFloat = parseFloat(formatUnits(balance, decimals ?? 18));
  const balanceStr = balanceFloat.toString();
  const fixed = balanceStr.includes(".")
    ? balanceStr.slice(balanceStr.indexOf(".") + 1, balanceStr.length).length
    : 0;

  return balanceFloat.toFixed(fixed < 4 ? fixed : 4);
};
