import React, { useState } from "react";
import classes from "./SwapParachain.module.css";
import {
  useModalByName,
  Processing,
  TokenPanel,
  TokensPanel,
} from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { SelectTokenModal } from "../../../../Tokens";
import { ConnectWalletModal } from "../../../../Account/components/ConnectWalletModal";
import { useParachainTokensPanel } from "../../../../TokensPanel";
import { useWalletContext } from "../../../../../common/context/Wallet";
import { useSwapParams } from "../../hooks/useSwap";
import { TransactionModal } from "../TransactionModal";
import { SwapInfoCard } from "../SwapInfoCard";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import Skeleton from "react-loading-skeleton";
import { formatUnits } from "ethers";

export const SwapParachain = () => {
  const { modal, closeModal, changeModal } = useModalByName();

  const { account, api, injector } = useWalletContext();
  const [inputFrom, setInputFrom] = useState("");

  const {
    tokens,
    setTokens,
    selectToken,
    token0Balance,
    token1Balance,
    error,
  } = useParachainTokensPanel(account, modal, inputFrom);

  const { outputAmount, resError, swapParams, loading } = useSwapParams(
    tokens,
    inputFrom,
  );

  const setMax = () => {
    const maxBalance = formatUnits(token0Balance, tokens[0]?.decimals);
    setInputFrom(maxBalance);
  };

  return (
    <div className={classNames(classes.Wrapper, "card")}>
      <div className={classNames(classes.Headline, "space-between")}>
        <p className="p1 semi">Swap</p>
      </div>
      <TokensPanel setTokens={() => setTokens([tokens[1], tokens[0]])}>
        <TokenPanel
          token0={tokens[0]}
          title="From"
          tokenBalance={getShowBalance(token0Balance, tokens[0]?.decimals)}
          setValue={setInputFrom}
          value={inputFrom}
          tokenSelectHandle={() => changeModal("token-from")}
          setAmount={setMax}
        />
        <TokenPanel
          token0={tokens[1]}
          title="To"
          tokenBalance={getShowBalance(token1Balance, tokens[1]?.decimals)}
          value={outputAmount}
          tokenSelectHandle={() => changeModal("token-to")}
        />
      </TokensPanel>
      {loading ? (
        <div style={{ width: "100%" }}>
          <Skeleton count={4} />
        </div>
      ) : (
        swapParams !== undefined && (
          <SwapInfoCard tokens={tokens} swapParams={swapParams} />
        )
      )}
      {account ? (
        <button
          className={"btn block"}
          onClick={() => changeModal("swap")}
          disabled={!!error || !!resError || modal === "swap"}
        >
          {error ?? resError ?? (modal === "swap" ? <Processing /> : "Swap")}
        </button>
      ) : (
        <button
          className={"btn block"}
          onClick={() => changeModal("wallet")}
          disabled={error === "Coming soon"}
        >
          {error === "Coming soon" ? "Coming soon" : "Connect wallet"}
        </button>
      )}
      {(modal === "token-to" || modal === "token-from") && (
        <SelectTokenModal
          toggleModal={closeModal}
          selectToken={selectToken}
          token={modal === "token-to" ? tokens[1] : tokens[0]}
        />
      )}
      {modal === "wallet" && <ConnectWalletModal toggleModal={closeModal} />}
      {modal === "swap" &&
        swapParams &&
        account &&
        injector &&
        tokens[0] &&
        tokens[1] &&
        api && (
          <TransactionModal
            inputFrom={inputFrom}
            token0={tokens[0]}
            token1={tokens[1]}
            swapParams={swapParams}
            api={api}
            account={account}
            injector={injector}
            onCloseButtonClick={() => closeModal()}
          />
        )}
    </div>
  );
};
