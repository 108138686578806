import { WalletContextType, WalletStorageType } from "./types";
import React, { useEffect, useState, JSX } from "react";
import {
  web3Accounts,
  web3Enable,
  web3FromAddress,
} from "@polkadot/extension-dapp";
import {
  InjectedAccountWithMeta,
  InjectedExtension,
} from "@polkadot/extension-inject/types";
import { AUTH_WALLET } from "../../../modules/Account/constants/localStorage";
import { getPolkadotApi } from "../../_parachain/libs/getPolkadotApi";
import { ApiPromise } from "@polkadot/api";
import { get777FormatAddress } from "../../_parachain/libs/get777FormatAddress";
import { env } from "../../../core/env";

export const WalletContext = React.createContext<WalletContextType | null>(
  null,
);

export const WalletProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [account, setAccount] = useState<string | undefined>();
  const [injector, setInjector] = useState<InjectedExtension | undefined>();
  const [walletName, setWalletName] = useState<string | undefined>();
  const [api, setApi] = useState<ApiPromise>();

  const connect = async (
    walletName: string,
  ): Promise<InjectedAccountWithMeta[]> => {
    try {
      const extensions = await web3Enable("Curio Parachain");

      if (extensions.length) {
        const allAccounts = await web3Accounts();
        if (window.walletExtension?.isNovaWallet) {
          if (walletName === "nova-wallet") {
            setWalletName(allAccounts.length ? walletName : undefined);
            return allAccounts;
          } else {
            return [];
          }
        } else {
          // get accounts from certain wallet extension
          const walletAccounts = allAccounts.filter(
            (item) => item.meta.source === walletName,
          );
          // if accounts found we set wallet name for using in dapp
          setWalletName(walletAccounts.length ? walletName : undefined);
          return walletAccounts;
        }
      }
      setWalletName(undefined);
      return [];
    } catch (e) {
      setWalletName(undefined);
      return [];
    }
  };

  useEffect(() => {
    if (account) {
      web3FromAddress(account).then((res) => {
        setInjector(res);
      });
    } else {
      setInjector(undefined);
    }
  }, [account]);

  const clearState = () => {
    localStorage.removeItem(AUTH_WALLET);
    setAccount(undefined);
    setWalletName(undefined);
  };

  const selectAddress = async (address: string) => {
    try {
      localStorage.setItem(
        AUTH_WALLET,
        JSON.stringify({ walletName, account: address }),
      );
      setAccount(get777FormatAddress(address));
    } catch (e) {
      await clearState();
    }
  };

  useEffect(() => {
    (async () => {
      const wallet = localStorage.getItem(AUTH_WALLET);
      if (wallet) {
        const walletObj: WalletStorageType = JSON.parse(wallet);
        // get account via walletName from storage
        const accounts = await connect(walletObj.walletName);

        // search account from account list
        const account = accounts.filter(
          (account) =>
            account.address.toLowerCase() === walletObj.account.toLowerCase(),
        )[0];
        if (account) {
          setAccount(get777FormatAddress(account.address));
          setWalletName(walletObj.walletName);
        } else {
          // clear state if account not found
          console.log("error");
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (env.CURIO_PARACHAIN === "true") {
      (async () => {
        try {
          const { api } = getPolkadotApi();
          await api.isReadyOrError;
          setApi(api);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, []);

  return (
    <WalletContext.Provider
      value={{
        account,
        connect,
        injector,
        selectAddress,
        api,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
