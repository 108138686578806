import { useState, useEffect } from "react";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { useWalletContext } from "../../../../common/context/Wallet";
import { IFarmPool } from "@curiodao/capital-dex-sdk/polkadot";

export const useAPY = (pool: IFarmPool) => {
  const [apy, setApy] = useState("0");
  const { api } = useWalletContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (api) {
      const farms = new FarmsService(api);
      setLoading(true);
      farms.getFarmAPY(pool.token0, pool.token1).then((res) => {
        setApy(res);
      });
      setLoading(false);
    }
  }, [api, pool.token0, pool.token1]);

  return { apy, loading };
};
