import { Navigate, useParams } from "react-router-dom";
import { RemoveLiquidity } from "../../modules/Pools/_parachain/components/RemoveLiquidity";
import { RemoveEthLiquidity } from "../../modules/Pools/_evm/components/RemoveLiquidity";
import { getTokenInfoBySymbol } from "../../modules/Tokens";
import { useNetworkContext } from "../../modules/Network";
import { IPage } from "../../common/interfaces/IPage";
import { useTokenListContext } from "../../contexts/TokenList";
import { getEvmTokenInfoBySymbol } from "@curiodao/capital-dex-sdk/evm";
import { TonRemoveLiquidity } from "../../modules/Pools";

export const RemoveLiquidityPage = ({ isParachain, isTon }: IPage) => {
  const { pair } = useParams();
  const { tokenList } = useTokenListContext();
  const { core } = useNetworkContext();

  if (!pair || pair.split("-").length !== 2) {
    return <Navigate to="/pools" />;
  }

  const token0 = getTokenInfoBySymbol(pair.split("-")[0] ?? "");
  const token1 = getTokenInfoBySymbol(pair.split("-")[1] ?? "");
  const ethToken0 = getEvmTokenInfoBySymbol(
    pair.split("-")[0] ?? "",
    tokenList,
  );
  const ethToken1 = getEvmTokenInfoBySymbol(
    pair.split("-")[1] ?? "",
    tokenList,
  );

  return (
    <>
      {isParachain ? (
        token0 && token1 ? (
          <RemoveLiquidity tokens={[token0, token1]} />
        ) : (
          <Navigate to="/pools" />
        )
      ) : core ? (
        ethToken0 && ethToken1 ? (
          <RemoveEthLiquidity tokens={[ethToken0, ethToken1]} />
        ) : (
          <Navigate to="/pools" />
        )
      ) : isTon && ethToken0 && ethToken1 ? (
        <TonRemoveLiquidity tokens={[ethToken0, ethToken1]} />
      ) : (
        <div />
      )}
    </>
  );
};
