import React from "react";
import classes from "./PoolsPosition.module.css";
import { PoolEthInfo } from "../PoolEthInfo";
import { usePoolsInfo } from "../../hooks/usePoolsInfo";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import Skeleton from "react-loading-skeleton";

export const PoolsEthPosition = () => {
  const { address } = useWeb3ModalAccount();
  const { positions, loading } = usePoolsInfo(address);

  const sortedPools = positions?.sort((a, b) => {
    return (
      Number(b.userPoolBalance.toFixed(7)) -
      Number(a.userPoolBalance.toFixed(7))
    );
  });

  return loading ? (
    <div style={{ width: "100%" }}>
      <Skeleton height={64} count={3} />
    </div>
  ) : (
    <div className={classes.PoolsBlock}>
      {sortedPools.length > 0 ? (
        <div className={classes.PoolsPosition}>
          {sortedPools.map((position, idx) => (
            <PoolEthInfo key={idx} position={position} />
          ))}
        </div>
      ) : (
        <p className="p2 card">No liquidity found</p>
      )}
    </div>
  );
};
