import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const mainnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.BOBA,
    name: "Boba Mainnet",
    rpcUrl: "https://mainnet.boba.network",
    currency: "ETH",
    explorerUrl: "https://bobascan.com",
  },
  cgt: "0xF56b164efd3CFc02BA739b719B6526A6FA1cA32a",
  whitelist: "0x94ceD2eB6AB0C43dd0CEBA7D0389858729D140A3",
  multicall: "0x211dA8112F2bD1c493a4F9158b9Aa1fa7342a8E8",
  router: "0xBb3e118D9382EB9a0286Df5e8EfC2846a109311e",
  blocksPerYear: 31536000,
  farming: [
    {
      masterChef: "0xc980253d019E991096eD7d12B9bC23d3f448202d",
      rewardToken: "CGT",
      pools: [
        {
          pid: 0,
          pairAddress: "0x74CA8aC86D104fcaF3501f986346D4Db5b28686c",
          token0Symbol: "CGT",
          token1Symbol: "DAI",
        },
        {
          pid: 1,
          pairAddress: "0xa02Ec1614c601BadD395D3e2124Ddea5Cb35C1E8",
          token0Symbol: "CGT",
          token1Symbol: "BOBA",
        },
      ],
    },
    {
      masterChef: "0xB57eeefc8e1CBEd9265A3B63AcA88D791bc45df1",
      rewardToken: "BOBA",
      pools: [
        {
          pid: 0,
          pairAddress: "0x74CA8aC86D104fcaF3501f986346D4Db5b28686c",
          token0Symbol: "CGT",
          token1Symbol: "DAI",
        },
        {
          pid: 1,
          pairAddress: "0xa02Ec1614c601BadD395D3e2124Ddea5Cb35C1E8",
          token0Symbol: "CGT",
          token1Symbol: "BOBA",
        },
      ],
    },
  ],
};
