import React from "react";
import classes from "./Bitcoin.module.css";
import bitcoinImage from "../../assets/bitcoin-image.png";

export const BitcoinSection = (): JSX.Element => {
  return (
    <section className={classes.Section} id="curio-bitcoin-chain">
      <div className={classes.Content}>
        <div className={classes.LeftBlock}>
          <div className={classes.TextBlock}>
            <h1 className="h2">Get on curio bitcoin chain</h1>
            <p className="p1">
              Curio BitSpectrum is an{" "}
              <a href="https://rgb.tech/" target="_blank" rel="noreferrer">
                RGB-based
              </a>{" "}
              protocol innovating in the Bitcoin ecosystem with its focus on
              Layer2 development and a multi-chain liquidity management platform
              utilizing the Substrate Network, Taproot Assets Protocol and
              Account - FI implementation.
            </p>
          </div>
          <div className={classes.Buttons}>
            <a
              className="btn medium"
              href="https://bitspectrum.capitaldex.exchange"
              target="_blank"
              rel="noreferrer"
            >
              Launch BitSpectrun
            </a>
            <a
              className="btn secondary medium"
              href="/docs/Curio_BitSpectrum_Protocol_CGT_2_0.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className={classes.ImageBlock}>
          <img src={bitcoinImage} alt="bitcoin chain image" />
        </div>
      </div>
    </section>
  );
};
