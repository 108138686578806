import { useEffect, useState } from "react";
import { TokenService } from "../services/Token.service";
import { useWalletContext } from "../../../../common/context/Wallet";
import { useBlock } from "../../../../common/hooks/useBlock";
import { IToken } from "@curiodao/capital-dex-sdk";
import { BTC } from "../../../../contexts/TokenList/constants";

export const useTokenBalance = (token: IToken | undefined) => {
  const [balance, setBalance] = useState("-");
  const { api, account } = useWalletContext();
  const block = useBlock();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (account && token && api && token.symbol !== BTC.symbol) {
      (async () => {
        const tokens = new TokenService(account, api);
        setLoading(true);
        if (token.symbol === "CGT" || !token.symbol) {
          tokens.getCGTBalance().then((res) => {
            setBalance(res.toString());
          });
          setLoading(false);
        } else {
          tokens.getBalance(token).then((res) => {
            setBalance(res.free.toString());
          });
          setLoading(false);
        }
      })();
    } else {
      setBalance("0");
      setLoading(false);
    }
  }, [account, token, api, block]);

  return { balance, loading };
};
