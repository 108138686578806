import React from "react";
import classes from "./Earned.module.css";
import {
  FarmsBalanceCard,
  PageDescription,
} from "@curiodao/capital-dex-ui-kit";
import img from "../../../assets/Farms.png";
import cgtToken from "../../../../../assets/img/icons/cgt-token.svg";
import { useTonTokenBalance } from "../../../../Tokens";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { useTokenListContext } from "../../../../../contexts/TokenList";
import { getEvmTokenInfoBySymbol } from "@curiodao/capital-dex-sdk/evm";
import { useFarmTotalSupply } from "../../hooks/useFarmTotalSupply";

export const EarnedSection = () => {
  const { tokenList } = useTokenListContext();

  const { balance: cgtBalance } = useTonTokenBalance(
    getEvmTokenInfoBySymbol("jCGT2.0", tokenList),
  );
  const { totalSupply } = useFarmTotalSupply();

  return (
    <div className="card">
      <div className={classes.Hero}>
        <p className="p1 semi">Earn CGT by staking CLP</p>
        <PageDescription
          img={img}
          text="In addition to the standard rewards for liquidity providers as exchange fees from traders, farming pools allow you to get rewards in CGT."
        />
        <p className="p2">
          CGT rewards in the TON network will be distributed once a month to
          liquidity providers wallets. Just needs to add liquidity to pairs
          below. The total amount of rewards per month will be distributed in
          proportion to the amount of liquidity - LP tokens in the wallet.
        </p>
      </div>
      <div className={classes.Cards}>
        <FarmsBalanceCard
          title="Total supply CGT"
          text="Rewards per month"
          value={totalSupply}
          tokenIcon={cgtToken}
          balance="400000 CGT"
        />
        <FarmsBalanceCard
          title={`Balance CGT`}
          value={getShowBalance(cgtBalance)}
          balance=""
          tokenIcon={cgtToken}
        />
      </div>
    </div>
  );
};
