import React from "react";
import classes from "./Earned.module.css";
import {
  FarmsBalanceCard,
  PageDescription,
} from "@curiodao/capital-dex-ui-kit";
import img from "../../../assets/Farms.png";
import cgtToken from "../../../../../assets/img/icons/cgt-token.svg";
import { useFarmTotalSupply } from "../../hooks/useFarmTotalSupply";
import { useNetworkContext } from "../../../../Network";
import { useEarned } from "../../hooks/useEarned";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useEthTokenBalance } from "../../../../Tokens";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { formatUnits } from "ethers";
import { useTokenListContext } from "../../../../../contexts/TokenList";
import { getEvmTokenInfoBySymbol } from "@curiodao/capital-dex-sdk/evm";
import Skeleton from "react-loading-skeleton";
import { isTestnet } from "../../../../../core/isTesnet";

export const EarnedSection = () => {
  const { core, network } = useNetworkContext();
  const { address } = useWeb3ModalAccount();
  const { tokenList } = useTokenListContext();

  const { info: totalSupply, loading: farmLoading } = useFarmTotalSupply();
  const { balance: cgtBalance, loading: balanceLoading } = useEthTokenBalance(
    getEvmTokenInfoBySymbol(
      isTestnet
        ? "CGT"
        : network === "skale"
          ? undefined
          : network === "neon"
            ? "CGT2"
            : "CGT",
      tokenList,
    ),
  );
  const { rewards: pendingRewards, loading: earnedLoading } = useEarned(
    core?.farming,
    address,
  );
  const rewardPerMonth = (core?.farming ?? [])
    .map((farm) => farm.rewardPerMonth ?? 0)
    .reduce((accumulator, value) => accumulator + value);

  return (
    <div className="card">
      <div className={classes.Hero}>
        <p className="p1 semi">Earn CGT by staking CLP</p>
        <PageDescription
          img={img}
          text="In addition to the standard rewards for liquidity providers as exchange fees from traders, farming pools allow you to get rewards in CGT."
        />
      </div>
      <div className={classes.Cards}>
        <FarmsBalanceCard
          title="Total supply CGT"
          text="Rewards per month"
          value={
            farmLoading ? (
              <Skeleton height={40} count={1} />
            ) : (
              (totalSupply ?? "-")
            )
          }
          tokenIcon={cgtToken}
          balance={`${rewardPerMonth} CGT`}
        />
        <FarmsBalanceCard
          title={`Balance CGT`}
          value={
            balanceLoading ? (
              <Skeleton height={40} count={1} />
            ) : network === "skale" && !isTestnet ? (
              "-"
            ) : (
              getShowBalance(cgtBalance)
            )
          }
          text="Pending harvest"
          balance={
            earnedLoading ? (
              <Skeleton height={19} width={60} count={1} />
            ) : (
              parseFloat(formatUnits(pendingRewards)).toFixed(4)
            )
          }
          tokenIcon={cgtToken}
        />
      </div>
    </div>
  );
};
