import React from "react";
import classes from "./Chains.module.css";
import parachain from "../../assets/chains.svg";
import { ShevronIcon } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";

export const ChainsSection = (): JSX.Element => {
  return (
    <section className={classes.Section}>
      <div className={classes.Content}>
        <div className={classes.TopCard}>
          <div className={classes.ImageBox}>
            <img src={parachain} alt="Curio parachain" />
          </div>
          <div className={classes.TopDescriptionBox}>
            <h2 className="h2">Curio chain</h2>
            <p className="p1">
              Curio chain is Curio&#39;s network built on the Substrate
              framework and connected to the Kusama network that provides shared
              security for the Curio chain. Curio chain is positioned primarily
              as a real-world assets{" "}
              <a
                href="https://www.alchemy.com/overviews/what-is-an-appchain"
                target="_blank"
                rel="noreferrer"
              >
                application-specific blockchain
              </a>{" "}
              (RWA Appchain) - a network for the tokenization of real-world
              assets.
            </p>
            <div className={classes.Buttons}>
              {/* TODO */}
              <a
                className="btn medium"
                href="https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fparachain.curioinvest.com#/explorer"
                target="_blank"
                rel="noreferrer"
              >
                Curio chain app
              </a>
              {/* TODO */}
              <a
                className="btn transparent medium"
                href="https://wiki.parachain.curioinvest.com"
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
        <div className={classes.DoubleCards}>
          <div className={classes.MiniCard}>
            <h4 className="h4">AMM DEX</h4>
            <p className="p2">
              In addition, the Curio chain has such functionality as AMM DEX
              (Capital DEX) with liquidity pools and farming, dPoS staking,
              on-chain OpenGov voting, EVM compatibility, XCM asset transfer
              channels with other chains such as{" "}
              <a
                href="https://moonbeam.network/networks/moonriver/"
                target="_blank"
                rel="noreferrer"
              >
                Moonriver
              </a>
              ,{" "}
              <a href="https://sora.org/" target="_blank" rel="noreferrer">
                SORA
              </a>
              ,{" "}
              <a
                href="https://unique.network/quartz/"
                target="_blank"
                rel="noreferrer"
              >
                Quartz by Unique
              </a>
              . The base native coin in the Curio chain is the CGT.
            </p>
            <div className={classes.Links}>
              <a
                className={classNames("p2 semi", classes.Link)}
                href="https://kusama.subscan.io/parachain/3339"
                target="_blank"
                rel="noreferrer"
              >
                Curio chain on Subscan
                <ShevronIcon />
              </a>
              <a
                className={classNames("p2 semi", classes.Link)}
                href="https://parachains.info/details/curio/"
                target="_blank"
                rel="noreferrer"
              >
                Curio chain on Parachains.info
                <ShevronIcon />
              </a>
            </div>
          </div>
          <div className={classes.MiniCard}>
            <h4 className="h4">How to use</h4>
            <div className={classes.LinksList}>
              <a
                className={classNames("p2 semi", classes.QuestionLink)}
                href="https://wiki.parachain.curioinvest.com/guides/how-to-create-and-connect-the-wallet-to-curio-parachain"
                target="_blank"
                rel="noreferrer"
              >
                <ShevronIcon />
                How to create and connect the wallet to Curio Parachain?
              </a>
              <a
                className={classNames("p2 semi", classes.QuestionLink)}
                href="https://wiki.parachain.curioinvest.com/guides/how-to-use-capital-dex-bridge-on-curio-parachain"
                target="_blank"
                rel="noreferrer"
              >
                <ShevronIcon />
                How to use Capital DEX Bridge on Curio Parachain?
              </a>
              <a
                className={classNames("p2 semi", classes.QuestionLink)}
                href="https://wiki.parachain.curioinvest.com/guides/how-to-use-capital-dex-on-curio-parachain"
                target="_blank"
                rel="noreferrer"
              >
                <ShevronIcon />
                How to use Capital DEX on Curio Parachain?
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
