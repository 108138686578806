export const FirstLiquidity = () => {
  return (
    <>
      <h3 className="p2 semi">You are the first liquidity provider</h3>
      <p className="p2" style={{ color: "var(--black-500)" }}>
        The ratio of tokens you add will set the price of this pool. Once you
        are happy with the rate click supply to review.
      </p>
    </>
  );
};
