import { ApiPromise } from "@polkadot/api";
import { Token } from "capital-dex-parachain-sdk/build/sdk-core";
import { IToken } from "@curiodao/capital-dex-sdk";

export class TokenService {
  constructor(
    private account: string,
    private api: ApiPromise,
  ) {}

  async getCGTBalance() {
    const total = await this.api.query.system.account(this.account);
    const locks = await this.api.query.balances.locks(this.account);
    const lock = locks.map((t) => t.amount)[0];

    return total.data.free.toBigInt() - (lock ? lock.toBigInt() : 0n);
  }

  async getBalance(token: IToken) {
    return this.api.query.tokens.accounts(
      this.account,
      Token.fromCurrencyName(token.symbol).toCurrencyId(this.api),
    );
  }
}
