import { useEffect, useState } from "react";
import { useNetworkContext } from "../../../Network";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { FarmService, IFarmPool } from "@curiodao/capital-dex-sdk/evm";
import { useBlock } from "../../../../common/hooks/useBlock";

export const usePoolBalances = (pool: IFarmPool, account?: string) => {
  const { core, chainId } = useNetworkContext();
  const [staked, setStaked] = useState<bigint>(0n);
  const [balance, setBalance] = useState<bigint>(0n);
  const { walletProvider } = useWeb3ModalProvider();
  const [loading] = useState(false);
  const block = useBlock();

  // убрала пока лоадинг, каждые 10 секунд странно видеть загрузку, она должна быть только в начале
  useEffect(() => {
    if (core && pool && account && walletProvider) {
      (async () => {
        const farms = new FarmService(pool);
        const staked = await farms.getStaked(account, walletProvider);
        const userBalance = await farms.getBalance(account, walletProvider);
        setStaked(staked);
        setBalance(userBalance);
      })();
    }
  }, [core, chainId, account, pool, walletProvider, block]);

  return { staked, balance, loading };
};
