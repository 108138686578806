import { isTestnet } from "../../../isTesnet";

const nym = {
  name: "Neon via NYM",
  rpcUrl: isTestnet
    ? "https://neon-devnet.nym.capitaldex.exchange"
    : "https://neon-mainnet.nym.capitaldex.exchange",
};

export { nym };
