import React from "react";
import classes from "./DaoSection.module.css";
import cgtImage from "../../assets/cgt-icon.svg";
import { Link } from "react-router-dom";

export const DaoSection = (): JSX.Element => {
  return (
    <section className={classes.Section}>
      <div className={classes.CardWrapper}>
        <div className={classes.TextBlock}>
          <h2 className="h2">CurioDAO</h2>
          <p className="p1">
            CurioDAO is the governance mechanism for the Curio chain and dApps
            that allows it to evolve gracefully overtime at the ultimate behest
            of its assembled stakeholders. CurioDAO is based on the Polkadot
            OpenGov model and includes on-chain voting mechanisms such as
            referenda and batch approval voting. All changes to the protocol
            must be agreed upon by stake-weighted referenda. The voting token in
            Curio OpenGov is CGT.
          </p>
          <div className={classes.Buttons}>
            <Link to="/swap" className="btn medium">
              Buy CGT
            </Link>
            <a
              className="btn transparent medium"
              href="https://wiki.polkadot.network/docs/learn-polkadot-opengov"
              target="_blank"
              rel="noreferrer"
            >
              About OpenGov
            </a>
            <a
              className="btn transparent medium"
              href="https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fparachain.curioinvest.com#/referenda"
              target="_blank"
              rel="noreferrer"
            >
              Explore Curio OpenGov
            </a>
          </div>
        </div>
        <div className={classes.ImageBlock}>
          <img src={cgtImage} alt="CGT icon" />
        </div>
      </div>
    </section>
  );
};
