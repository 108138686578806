import { IFarmInfo } from "../../../../common/_evm/interfaces/IFarmInfo";
import { IToken } from "@curiodao/capital-dex-sdk";
import {
  IFarmPool,
  getEvmTokenInfoBySymbol,
} from "@curiodao/capital-dex-sdk/evm";

const getToken = (
  symbol: string,
  evmTokenList: IToken[],
): IToken | undefined => {
  const token = getEvmTokenInfoBySymbol(symbol, evmTokenList);
  const weth = token?.isNative
    ? getEvmTokenInfoBySymbol(`w${token.symbol}`, evmTokenList)
    : undefined;

  return weth && token
    ? {
        ...token,
        address: weth.address,
      }
    : token;
};

export const getFarmPools = (
  farm: IFarmInfo[],
  evmTokenList: IToken[],
): IFarmPool[] => {
  const farmPools: IFarmPool[] = [];

  farm.map((farm) => {
    farm.pools.map((pool) => {
      const token0 = getToken(pool.token0Symbol, evmTokenList);
      const token1 = getToken(pool.token1Symbol, evmTokenList);

      const rewardToken = getEvmTokenInfoBySymbol(
        farm.rewardToken,
        evmTokenList,
      );

      if (token0 && token1 && rewardToken) {
        farmPools.push({
          token0,
          token1,
          rewardToken,
          masterChef: farm.masterChef,
          pid: pool.pid,
          lpAddress: pool.pairAddress,
        });
      }
    });
  });

  return farmPools;
};
