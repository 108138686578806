import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const testnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.BSC_TESTNET,
    name: "BNB Smart Chain Testnet",
    rpcUrl: "https://data-seed-prebsc-1-s1.bnbchain.org:8545",
    currency: "BNB",
    explorerUrl: "https://testnet.bscscan.com",
  },
  cgt: "0x29f4932E46fD2198e7089Af2a08a50C036337E6d",
  whitelist: "0xE28039D9148A31E0ce8EAd889732a555174dDF7E",
  multicall: "0x715D0aE552FE5B04A0C3ee7078Ba2fbb804Cc2A3",
  router: "0x8F01Fad188e1f19c01869277aD376a98be327cfd",
  blocksPerYear: 10512000,
  farming: [
    {
      masterChef: "0x25299aDbB5a47C4bceB268458550313491749cde",
      rewardToken: "CGT",
      pools: [
        {
          pid: 0,
          token0Symbol: "BNB",
          token1Symbol: "CGT",
          pairAddress: "0xCc9e69aED706aC49EBC999755AaD9e9cEF429De4",
        },
        {
          pid: 1,
          token0Symbol: "CGT",
          token1Symbol: "DAI",
          pairAddress: "0x7d9345381284E43B36fFB911CfcEb3285C4B296f",
        },
      ],
    },
  ],
};
