import React from "react";
import { StatisticsBlock } from "../modules/Staking/components/StatisticsBlock";
import { StakingBlock } from "../modules/Staking/components/StakingBlock";

export const StakingPage = () => {
  return (
    <>
      <StatisticsBlock />
      <StakingBlock />
    </>
  );
};
