import axios from "axios";
import { isTestnet } from "../core/isTesnet";
import { TonClient } from "@ton/ton";
import { env } from "../core/env";

const tonApiUrl = `https://${isTestnet ? "testnet." : ""}tonapi.io/v2`;

export const tonApiClient = axios.create({
  baseURL: tonApiUrl,
});

export const tonDexClient = axios.create({
  baseURL: `https://${isTestnet ? "testnet." : ""}ton.capitaldex.exchange/api/v1`,
});

export const tonClient = new TonClient({
  endpoint: `https://${isTestnet ? "testnet." : ""}toncenter.com/api/v2/jsonRPC`,
  apiKey: env.TON_CENTER_KEY,
});
