import { IMenuItem } from "../interfaces/IMenuItem";

export const MENU: IMenuItem[] = [
  {
    id: "swap",
    title: "Swap",
    link: "/swap",
  },
  {
    id: "pools",
    title: "Pools",
    link: "/pools",
  },
  {
    id: "farms",
    title: "Farms",
    link: "/farms",
  },
  {
    id: "cgt",
    title: "CGT",
    link: "/cur-to-cgt",
  },
  {
    id: "wrapping",
    title: "Wrapping",
    link: "/wrapping",
  },
  {
    id: "staking",
    title: "Staking",
    link: "/staking",
  },
];
