import near from "../assets/partners/near.svg";
import metamask from "../assets/partners/metamask.svg";
import polkadot from "../assets/partners/polkadot.svg";
import chainlink from "../assets/partners/chainlink.svg";
import coinbase from "../assets/partners/coinbase.svg";
import portis from "../assets/partners/portis.svg";
import bnbChain from "../assets/partners/bnb-chain.svg";
import skale from "../assets/partners/skale.svg";
import curioinvest from "../assets/partners/curioinvest.svg";
import walletConnect from "../assets/partners/wallet-connect.svg";
import makerdao from "../assets/partners/makerdao.svg";

export const partners = [
  {
    url: "https://near.org/",
    image: near,
  },
  {
    url: "https://metamask.io/",
    image: metamask,
  },
  {
    url: "https://polkadot.network/",
    image: polkadot,
  },
  {
    url: "https://chain.link/",
    image: chainlink,
  },
  {
    url: "https://www.coinbase.com/",
    image: coinbase,
  },
  {
    url: "https://www.portis.io/",
    image: portis,
  },
  {
    url: "https://www.bnbchain.org/en",
    image: bnbChain,
  },
  {
    url: "https://skale.space/",
    image: skale,
  },
  {
    url: "https://curioinvest.com/",
    image: curioinvest,
  },
  {
    url: "https://walletconnect.com/",
    image: walletConnect,
  },
  {
    url: "https://makerdao.com/",
    image: makerdao,
  },
];
