import { useEffect, useState } from "react";
import { useNetworkContext } from "../../../Network";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { IFarmInfo } from "../../../../common/_evm/interfaces/IFarmInfo";
import { RewardsService } from "@curiodao/capital-dex-sdk/evm";

export const useEarned = (
  farming?: IFarmInfo[],
  account?: string,
): { rewards: bigint; loading: boolean } => {
  const { walletProvider } = useWeb3ModalProvider();
  const { core, chainId } = useNetworkContext();
  const [rewards, setRewards] = useState(0n);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (walletProvider && core && farming && account) {
      (async () => {
        setLoading(true);
        const farms = new RewardsService(account);
        const earned = await farms.getEarned(farming, walletProvider);
        setRewards(earned);
        setLoading(false);
      })();
    }
  }, [walletProvider, core, chainId, account, farming]);

  return { rewards, loading };
};
