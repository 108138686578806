import { Input, Modal, TokenRow } from "@curiodao/capital-dex-ui-kit";
import React, { useState } from "react";
import classes from "./SelectTokenModal.module.css";
import { isTestnet } from "../../../../core/isTesnet";
import { useTokenListContext } from "../../../../contexts/TokenList";
import Skeleton from "react-loading-skeleton";
import { IToken } from "@curiodao/capital-dex-sdk";
import { useTokenBalance as useEthTokenBalance } from "../../_evm/hooks/useTokenBalance";
import { useTokenBalance as useTonTokenBalance } from "../../_ton/hooks/useTokenBalance";
import { getShowBalance } from "../../../../common/libs/getShowBalance";
import { useNetworkContext } from "../../../Network";

export const SelectTokenModal = ({
  toggleModal,
  selectToken,
  token,
  balances,
}: {
  toggleModal: () => void;
  selectToken: (token: IToken) => void;
  token: IToken | undefined;
  balances?: { token: IToken; balance: string }[];
}) => {
  const { tokenList, loading } = useTokenListContext();
  const [searchTerm, setSearchTerm] = useState("");
  const { isParachain, isTon } = useNetworkContext();

  console.log("tokenList", tokenList);

  const filteredTokens = tokenList.filter(
    (item) =>
      (item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.symbol.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (isTestnet ? true : !item.onlyTestnet),
  );
  const nativeToken = tokenList.find((token) => token.isNative);
  const { balance } = useEthTokenBalance(
    !isTon && !isParachain ? nativeToken : undefined,
  );
  const { balance: tonBalance } = useTonTokenBalance(
    isTon ? nativeToken : undefined,
  );

  console.log("tokenList", tokenList);
  return (
    <Modal onCloseButtonClick={toggleModal} title="Select a token">
      <div className={classes.ModalInner}>
        <Input>
          <input
            placeholder="Search name or paste address"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </Input>
        {loading ? (
          <Skeleton height={44} count={5} />
        ) : (
          <ul className={classes.TokensList}>
            {filteredTokens
              .filter((item) => item.symbol !== token?.symbol)
              .map((item) => (
                <li key={`${item.name}-${item.symbol}`}>
                  <TokenRow
                    token={item}
                    onClick={() => {
                      selectToken(item);
                      toggleModal();
                    }}
                    balance={
                      item.isNative
                        ? getShowBalance(
                            isTon ? tonBalance : balance,
                            item.decimals,
                          )
                        : balances?.length && item?.symbol
                          ? balances.filter(
                              (balance) => balance.token.symbol === item.symbol,
                            )[0]?.balance
                          : ""
                    }
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </Modal>
  );
};
