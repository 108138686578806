import { FixedPointNumber } from "capital-dex-parachain-sdk/build/sdk-core";

export const toHuman = (value: bigint | string | number, decimals?: number) => {
  const s = value.toString().padStart((decimals ?? 18) + 1, "0");

  // string because float can be very small
  const str =
    s.slice(0, s.length - (decimals ?? 18)) +
    "." +
    s.slice(s.length - (decimals ?? 18));

  return parseFloat(str).toString();
};

export const toToken = (value: string, decimals?: number): bigint => {
  return parseFloat(value)
    ? BigInt(
        new FixedPointNumber(value, decimals ?? 18).toChainData().toString(),
      )
    : 0n;
};

export const displayValue = (
  balance: bigint | string,
  decimals?: number,
  fixed?: number,
) => parseFloat(toHuman(balance, decimals ?? 18)).toFixed(fixed ?? 2);
