import React from "react";
import classes from "./Staking.module.css";
import stakingImage from "../../assets/stake-image.png";
import { Link } from "react-router-dom";

export const StakingSection = (): JSX.Element => {
  return (
    <section className={classes.Section}>
      <div className={classes.Content}>
        <div className={classes.ImageBlock}>
          <img src={stakingImage} alt="Staking sample" />
        </div>
        <div className={classes.TextBlock}>
          <h2 className="h2">Staking programs</h2>
          <p className="p1">
            Get CGT rewards for maintaining the Curio chain. CGT holders can
            participate in the dPoS consensus mechanism of the Curio chain using
            their CGT as a stake and be rewarded.
          </p>
          <div className={classes.Buttons}>
            <Link to="/staking?chain=curio-parachain" className="btn medium">
              Stake now
            </Link>
            <a
              className="btn transparent medium"
              href="https://wiki.parachain.curioinvest.com/learn/staking"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
            <a
              className="btn transparent medium"
              href="https://wiki.parachain.curioinvest.com/guides/how-to-stake-on-curio-parachain-as-delegator"
              target="_blank"
              rel="noreferrer"
            >
              How to stake
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
