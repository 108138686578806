import React from "react";
import classes from "./Partners.module.css";
import { partners } from "../../constants/partners";

export const PartnersSection = (): JSX.Element => {
  return (
    <section className={classes.Section}>
      <div className={classes.Container}>
        <h2 className="h2">Ecosystem partners</h2>
        <div className={classes.PartnersBlock}>
          <div className={classes.PartnersLinks}>
            {partners.map((item, index) => (
              <a
                href={item.url}
                target="_blank"
                key={index}
                className={classes.Link}
                rel="noreferrer"
              >
                <img src={item.image} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
