import { useEffect, useState } from "react";
import { JettonsService } from "../../../Tokens";
import { tonApiClient } from "../../../../config/clients";

export const useFarmTotalSupply = () => {
  const [totalSupply, setTotalSupply] = useState<string>("");
  useEffect(() => {
    (async () => {
      try {
        const jettons = new JettonsService(tonApiClient);
        const totalSupply = await jettons.getTotalSupply();
        totalSupply && setTotalSupply(totalSupply);
      } catch (error) {
        console.error(error);
        setTotalSupply("0");
      }
    })();
  }, []);
  return { totalSupply };
};
