import { createContext, useState, JSX } from "react";
import { NetworkContextType } from "./types";
import { NetworkService } from "../../services/Network.service";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import { env } from "../../../../core/env";

export const NetworkContext = createContext<NetworkContextType | null>(null);

export const NetworkProvider = ({ children }: { children: JSX.Element }) => {
  const [network, setNetwork] = useState("");
  // https://stackoverflow.com/a/58290541
  const [networkService] = useState(() => new NetworkService());

  const metadata = {
    name: "Capital DEX: DeFi Lego for RWAs powered by Curio Chain",
    description:
      "Swap, earn, farm crypto on the most cost-efficient decentralized exchange in the metaverse.",
    url: "https://capitaldex.exchange", // origin must match your domain & subdomain
    icons: ["https://avatars.mywebsite.com/"],
  };

  createWeb3Modal({
    projectId: env.WALLET_CONNECT_PROJECT_ID,
    ethersConfig: defaultConfig({ metadata }),
    chains: networkService.getEVMNetworkCredentials(),
    themeMode: "light",
    themeVariables: {
      "--w3m-font-family": "Inter,sans-serif;",
      "--w3m-border-radius-master": "3px",
      "--w3m-font-size-master": "9.5px",
    },
    allowUnsupportedChain: env.EVM === "true",
  });

  return (
    <NetworkContext.Provider
      value={{
        network,
        isParachain: networkService.isParachainNetwork(network),
        isTon: networkService.isTonNetwork(network),
        setNetwork,
        core: networkService.getNetworkCore(network),
        chains: networkService.getEVMNetworkCredentials(),
        chainId:
          networkService.getNetworkCore(network)?.networkCredentials.chainId ??
          0,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};
