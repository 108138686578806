import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const testnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.BOBA_TESTNET,
    name: "Boba Rinkeby",
    rpcUrl: "https://rinkeby.boba.network",
    currency: "ETH",
    explorerUrl: "https://blockexplorer.rinkeby.boba.network",
  },
  cgt: "0x9a8cc2c27d79B5DBF22Bba8Be44bbd5953fc6b1D",
  whitelist: "0xFc717050570398FA7ADB380ba76C1aF78D2Fd154",
  multicall: "0xCf65239F3622bCc03dB4Ea324EF00A2a1FA25989",
  router: "0xB7092f6ED01f56BA19d78D225E26aDfa9974F9d5",
  blocksPerYear: 31536000,
  farming: [
    {
      masterChef: "0xDeD6a71A65b0E771404df56ad66f7F29217ae13E",
      rewardToken: "CGT",
      rewardPerMonth: 50000,
      pools: [
        {
          pid: 0,
          pairAddress: "0x20f022DBDec395D824e0a9a88F72aBB3a0869384",
          token0Symbol: "CGT",
          token1Symbol: "DAI",
        },
        {
          pid: 1,
          pairAddress: "0x7E352479Ca90E813B0530D46F0dC00DF050f06d0",
          token0Symbol: "CGT",
          token1Symbol: "BOBA",
        },
      ],
    },
    {
      masterChef: "0xA6fd1dd45c2BAEAa4bC4e96638540747339Ee55d",
      rewardToken: "BOBA",
      pools: [
        {
          pid: 0,
          pairAddress: "0x20f022DBDec395D824e0a9a88F72aBB3a0869384",
          token0Symbol: "CGT",
          token1Symbol: "DAI",
        },
        {
          pid: 1,
          pairAddress: "0x7E352479Ca90E813B0530D46F0dC00DF050f06d0",
          token0Symbol: "CGT",
          token1Symbol: "BOBA",
        },
      ],
    },
  ],
};
