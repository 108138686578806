import classes from "./PoolInfo.module.css";
import { PoolCard, InfoRow } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { IPool } from "../../interfaces/pool";
import { usePool } from "../../hooks/usePool";
import { Link } from "react-router-dom";
import { useWalletContext } from "../../../../../common/context/Wallet";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { getTokenInfoBySymbol } from "../../../../Tokens";

export const PoolInfo = ({ pool }: { pool: IPool }) => {
  const { account } = useWalletContext();
  const { tokenA, tokenB, userShare } = pool;

  const { poolInfo } = usePool(pool, account);

  return (
    <PoolCard
      token0Icon={getTokenInfoBySymbol(tokenA.symbol)?.logoUri}
      token1Icon={getTokenInfoBySymbol(tokenB.symbol)?.logoUri}
      name={`${tokenA.symbol}-${tokenB.symbol}`}
      balance={getShowBalance(userShare)}
      empty={!userShare}
      href={`/pools/add/${tokenA.symbol}-${tokenB.symbol}`}
    >
      <div className={classes.CardInner}>
        <div className={classNames(classes.Info, "card bordered")}>
          <InfoRow
            title={`Pooled ${tokenA.symbol}`}
            value={`${getShowBalance(
              poolInfo?.tokenAReturns,
              tokenA.decimals,
            )} ${tokenA.symbol}`}
          />
          <InfoRow
            title={`Pooled ${tokenB.symbol}`}
            value={`${getShowBalance(
              poolInfo?.tokenBReturns,
              tokenB.decimals,
            )} ${tokenB.symbol}`}
          />
          <InfoRow
            title="Your pool tokens"
            value={`${getShowBalance(userShare)} ${tokenA.symbol}-${
              tokenB.symbol
            }`}
          />
          <InfoRow
            title="Your pool share"
            value={`${((poolInfo?.userPoolShare ?? 0) * 100).toFixed(2)}%`}
          />
          {/*<InfoRow*/}
          {/*  title="Pool information"*/}
          {/*  value={*/}
          {/*    <button className={classes.InfoButton}>*/}
          {/*      <p className="p3 semi">View details</p>*/}
          {/*      <ArrowIcon />*/}
          {/*    </button>*/}
          {/*  }*/}
          {/*/>*/}
        </div>
        <div className={classes.Buttons}>
          <Link
            to={`/pools/remove/${tokenA.symbol}-${tokenB.symbol}`}
            className="btn secondary block"
          >
            Remove
          </Link>
          <Link
            to={`/pools/add/${tokenA.symbol}-${tokenB.symbol}`}
            className="btn block"
          >
            Add
          </Link>
        </div>
      </div>
    </PoolCard>
  );
};
