import { useEffect, useState } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { IPosition } from "../../_parachain/interfaces/IPosition";
import { PoolsService } from "@curiodao/capital-dex-sdk/evm";

export const useRemoveLiquidityReturns = (
  position: IPosition | undefined,
  inputFrom: string,
) => {
  const { address } = useWeb3ModalAccount();
  const [tokenAReturns, setTokenAReturns] = useState(0);
  const [tokenBReturns, setTokenBReturns] = useState(0);

  useEffect(() => {
    if (position) {
      const poolService = new PoolsService();

      poolService.getRemoveReturns(position, inputFrom).then((res) => {
        setTokenAReturns(res.tokenAReturns);
        setTokenBReturns(res.tokenBReturns);
      });
    }
  }, [address, inputFrom, position]);

  return { tokenAReturns, tokenBReturns };
};
