import { AxiosInstance } from "axios";
import { jettonsToTokens } from "../mappers/jettonsToTokens";
import { IJetton } from "../interfaces/IJetton";
import { IToken } from "@curiodao/capital-dex-sdk";
import { IBalance } from "../interfaces/IBalance";
import { TonConnectUI } from "@tonconnect/ui-react";
import { tonApiClient, tonDexClient } from "../../../../config/clients";
import { toHuman } from "../../../../common/libs/support";

export class JettonsService {
  constructor(private dexClient: AxiosInstance) {}

  async fetchBalances(accountId: string): Promise<IBalance[]> {
    if (accountId !== "") {
      try {
        const { data } = await tonApiClient.get(
          `/accounts/${accountId}/jettons`,
        );

        return data.balances;
      } catch (e) {
        return [];
      }
    }
    return [];
  }

  async fetchBalanceTon(accountId: string): Promise<string | null> {
    if (accountId !== "") {
      try {
        const { data } = await tonApiClient.get(`/accounts/${accountId}`);

        return data.balance.toString();
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  async getJettonsList(): Promise<IJetton[] | null> {
    try {
      const { data } = await tonDexClient.get("/assets");
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async getTokenList(): Promise<IToken[] | undefined> {
    try {
      const data = await this.getJettonsList();
      return data ? jettonsToTokens(data) : undefined;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  transfer(destAddress: string, value: string, tonConnectUI: TonConnectUI) {
    const transaction = {
      validUntil: Date.now() + 1000000,
      messages: [
        {
          address: destAddress,
          amount: value,
        },
      ],
    };

    return tonConnectUI.sendTransaction(transaction);
  }

  async getTotalSupply() {
    try {
      const { data } = await tonApiClient.get(
        "/jettons/0:b5e9470f0ce1756017037c8dfe48d03c9ebf040a588244ddcee5dffe49205c51",
      );

      return parseFloat(toHuman(data.total_supply, 18)).toFixed(2);
    } catch (error) {
      console.error(error);
    }
  }
}
