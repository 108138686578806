import { parachainNetworks } from "../../../core/networks/parachain-networks";
import { env } from "../../../core/env";
import { EVM_NETWORKS_CORE } from "../../../core/networks/evm-networks";
import { INetworkCore } from "../../../common/_evm/interfaces/INetworkCore";
import { INetworkCredentials } from "../../../common/_evm/interfaces/INetworkCredentials";
import { Eip1193Provider } from "ethers";
import { TON_NETWORK } from "../../../core/networks/ton-network";

export class NetworkService {
  private readonly defaultNetworkId: string;
  public list: {
    name: string;
    icon: string;
    id: string;
    chainId?: number;
    nym?: boolean;
    disabled?: boolean;
  }[];

  private getAvailableNetworksId(): string[] {
    return this.list.map((network) => network.id);
  }

  private isAvailableNetwork(networkId: string | null) {
    return !!this.getAvailableNetworksId().find((id) => id === networkId);
  }

  constructor() {
    this.list = [];
    if (env.TON === "true") {
      this.list.push(TON_NETWORK);
    }
    if (env.CURIO_PARACHAIN === "true") {
      this.list.push(...parachainNetworks);
    }
    if (env.EVM === "true") {
      this.list.push(
        ...Object.keys(EVM_NETWORKS_CORE).map((key) => ({
          name: EVM_NETWORKS_CORE[key].name as string,
          icon: EVM_NETWORKS_CORE[key].icon as string,
          id: key,
          chainId: EVM_NETWORKS_CORE[key].networkCredentials.chainId,
          nym: EVM_NETWORKS_CORE[key].nym,
        })),
      );
    }

    this.defaultNetworkId = this.isAvailableNetwork(env.DEFAULT_NETWORK)
      ? env.DEFAULT_NETWORK
      : this.getAvailableNetworksId()[0];
  }

  isParachainNetwork(networkId: string): boolean {
    return !!parachainNetworks.find((n) => n.id === networkId);
  }

  isTonNetwork(networkId: string): boolean {
    return TON_NETWORK.id === networkId;
  }

  getUrlNetwork(searchParams: URLSearchParams): string {
    const networkIdFromUrl = searchParams.get("chain");
    return networkIdFromUrl &&
      networkIdFromUrl.length &&
      this.isAvailableNetwork(networkIdFromUrl)
      ? networkIdFromUrl
      : this.defaultNetworkId;
  }

  getNetworkIdFromChainId(chainId: number): string | undefined {
    return this.list.find((network) => network.chainId === chainId)?.id;
  }

  getListNumbByNetwork(networkId: string): number {
    return this.list.findIndex((network) => network.id === networkId);
  }

  getNetworkCore(networkId: string): INetworkCore | undefined {
    return EVM_NETWORKS_CORE[networkId];
  }

  getEVMNetworkCredentials(): INetworkCredentials[] {
    return Object.keys(EVM_NETWORKS_CORE).map(
      (key) => EVM_NETWORKS_CORE[key].networkCredentials,
    );
  }

  getEVMList() {
    return this.list.filter((item) => !!item.chainId);
  }

  async switchNetwork(networkId: string, walletProvider: Eip1193Provider) {
    const core = this.getNetworkCore(networkId);
    if (core) {
      const chainId = `0x${core.networkCredentials.chainId.toString(16)}`;
      // because use nym
      // const chainIdFromProvider = await walletProvider.request({
      //   method: "eth_chainId",
      // });
      return walletProvider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: chainId,
            chainName: core.networkCredentials.name,
            nativeCurrency: {
              name: core.networkCredentials.currency,
              symbol: core.networkCredentials.currency,
              decimals: 18,
            },
            rpcUrls: [core.networkCredentials.rpcUrl],
            blockExplorerUrls: [core.networkCredentials.explorerUrl],
          },
        ],
      });
    }
  }
}
