import { useEffect, useState } from "react";
import { ApiPromise } from "@polkadot/api";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { PARACHAIN_POOLS } from "../constants/parachain-pools";
import { PoolsService } from "@curiodao/capital-dex-sdk/polkadot";
import { displayValue, toHuman } from "../../../../common/libs/support";
import { useBlock } from "../../../../common/hooks/useBlock";
import { IToken } from "@curiodao/capital-dex-sdk";

export const usePendingHarvest = (
  api: ApiPromise | undefined,
  account: Account,
  rewardToken: IToken | undefined,
) => {
  const block = useBlock();
  const [pendingHarvest, setPendingHarvest] = useState<Web3Num | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (account && api) {
      if (rewardToken) {
        (async () => {
          setLoading(true);
          const farms = new FarmsService(api);
          const poolsService = new PoolsService(api, account);
          const pools = PARACHAIN_POOLS.filter(
            (pool) =>
              pool.token0.symbol === rewardToken.symbol ||
              pool.token1.symbol === rewardToken.symbol,
          );
          const rewards = await Promise.all(
            pools.map(async (farmPool) => {
              const pool = poolsService.getTokens(
                farmPool.token0,
                farmPool.token1,
              );
              return await farms.getHarvestRewards(
                account,
                pool.dexShare,
                rewardToken.symbol,
              );
            }),
          );
          const pendingHarvest = rewards
            .map((reward) => reward.bn)
            .reduce((total, value) => total + value);

          setPendingHarvest({
            dsp: displayValue(pendingHarvest, rewardToken.decimals),
            bn: pendingHarvest,
            str: toHuman(pendingHarvest, rewardToken.decimals),
          });
          setLoading(false);
        })();
      }
    }
  }, [account, api, block, rewardToken]);

  return { pendingHarvest, loading };
};
