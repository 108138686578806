import React from "react";
import classes from "./RwaTokens.module.css";
import carsImage from "../../assets/auto.svg";
import { Link } from "react-router-dom";

export const RwaTokensSection = (): JSX.Element => {
  return (
    <section className={classes.Section}>
      <div className={classes.Container}>
        <div className={classes.ImageCard}>
          <img src={carsImage} alt="Cars" />
        </div>
        <div className={classes.TextCard}>
          <h2 className="h2">RWA tokens</h2>
          <p className="p1">
            Tokenization is the creation of a digital asset and the registration
            of its rights on a blockchain. Curio provides a hybrid tokenization
            system for any real-world asset (for example, collectable cars)
            powered by its own Curio chain and other networks. Curio&#39;s
            tokenization protocol includes various types of tokens such as
            Fungible tokens, NFT and RFT, as well as tokenization on-chain
            voting using Curio OpenGov.
          </p>
          <div className={classes.Buttons}>
            <Link className="btn medium" to="/swap">
              Swap RWA
            </Link>
            <a
              className="btn secondary medium"
              href="https://curioinvest.com/products/"
              target="_blank"
              rel="noreferrer"
            >
              Explore assets
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
