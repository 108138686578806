import { useEffect, useState } from "react";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { PoolsService } from "@curiodao/capital-dex-sdk/evm";
import { IToken } from "@curiodao/capital-dex-sdk";

export const useEthAddLiquidityParams = (
  amountIn: string,
  tokenA: IToken | undefined,
  tokenB: IToken | undefined,
) => {
  const [addLiquidityParams, setAddLiquidityParams] = useState<{
    rates: string;
    poolShare: string;
    liquidityMinted: string;
    outputAmount: string;
  }>();
  const { tokenList } = useTokenListContext();
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId, core } = useNetworkContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (core && amountIn.length && tokenA && tokenB) {
          const poolsService = new PoolsService();
          setLoading(true);
          const params = await poolsService.getAddLiquidityParams(
            tokenList,
            amountIn,
            chainId,
            core.networkCredentials.rpcUrl,
            tokenA,
            tokenB,
            walletProvider,
          );
          setAddLiquidityParams(params);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching liquidity params:", error);
      }
    };

    fetchData();
  }, [amountIn, chainId, core, tokenList, tokenA, tokenB, walletProvider]);

  return { addLiquidityParams, loading };
};
