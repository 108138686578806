import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { useEffect, useState } from "react";
import { IFarmPool, FarmService } from "@curiodao/capital-dex-sdk/evm";
import { useBlock } from "../../../../common/hooks/useBlock";

export const useHarvest = (pool: IFarmPool, account?: string) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { core } = useNetworkContext();
  const [rewards, setRewards] = useState<bigint>(0n);
  const [isHarvesting, setIsHarvesting] = useState(false);
  const [loading] = useState(false);
  const block = useBlock();

  // убрала пока лоадинг, каждые 10 секунд странно видеть загрузку, она должна быть только в начале
  useEffect(() => {
    if (core && pool && account && walletProvider) {
      (async () => {
        const farms = new FarmService(pool);
        const earned = await farms.getPoolReward(account, walletProvider);
        setRewards(earned);
      })();
    }
  }, [core, account, pool, walletProvider, block]);

  const harvest = async () => {
    if (core && pool && walletProvider) {
      setIsHarvesting(true);
      const farm = new FarmService(pool);
      await farm.harvest(walletProvider);
      setIsHarvesting(false);
    }
  };

  return {
    harvest,
    isHarvesting,
    rewards,
    loading,
  };
};
