import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const testnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.NEON_DEVNET,
    name: "Neon EVM Devnet",
    rpcUrl: "https://245022926.rpc.thirdweb.com",
    currency: "NEON",
    explorerUrl: "https://neon-devnet.blockscout.com",
  },
  cgt: "0x02F61a069c53dE8CD0aD9BA3c94ce87a22923E54",
  router: "0x1ed71a140866c94Ec0d7854Fd32C296f323155f2",
  whitelist: "0xBE431FD2f2812eD09f59320f6a2A1476d9A5C3F6",
  multicall: "0xcFC8002c27985410F7a5Df76f418E5F1a460e1eb",
  blocksPerYear: 31536000,
  farming: [
    {
      masterChef: "0xFf98b9B26eb1d9Ba34946255f6fC7730b36213E1",
      rewardToken: "CGT",
      rewardPerMonth: 0,
      pools: [
        {
          pid: 0,
          pairAddress: "0x060A5e9c268Ee6Ed94A909c2A8263326483fafBe",
          token0Symbol: "NEON",
          token1Symbol: "CGT",
        },
        {
          pid: 1,
          pairAddress: "0xe937c697B55128dA35E3bA77Dd011E2FDE752c5f",
          token0Symbol: "CGT",
          token1Symbol: "USDT",
        },
      ],
    },
  ],
};
