import classes from "./Pool.module.css";
import { Pair, ShevronIcon } from "@curiodao/capital-dex-ui-kit";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useAPY } from "../../hooks/useAPY";
import { IFarmPool } from "@curiodao/capital-dex-sdk/polkadot";
import Skeleton from "react-loading-skeleton";

export const Pool = ({ pool }: { pool: IFarmPool }) => {
  const { token0, token1, id } = pool;
  const { apy, loading } = useAPY(pool);

  return (
    <Link className="card gap-16" to={`/farms/${id}`}>
      <div className={classNames(classes.Wrapper, "space-between")}>
        <Pair name={id} icon0={token0.logoUri} icon1={token1.logoUri} row />
        <div className={classes.Values}>
          <p className="p2">
            APY:{" "}
            {loading ? (
              <Skeleton height={17} width={50} count={1} />
            ) : (
              <span className="semi">{apy}%</span>
            )}
          </p>
          <ShevronIcon />
        </div>
      </div>
    </Link>
  );
};
