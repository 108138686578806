import { DEX } from "@ston-fi/sdk";
import { tonClient } from "./clients";
import { isTestnet } from "../core/isTesnet";

export const ROUTER = tonClient.open(
  DEX.v1.Router.create(
    isTestnet
      ? "EQD2jD-FXhYvmK86BrX8Pvu3fWhrXXzkiH7hzQSUBjb4CwFY"
      : "EQBn8irkO9ELo8OskpTq5DlGNh1G30zAazs0BvdGFn1iEwla",
  ),
);
