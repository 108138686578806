import { useState } from "react";
import {
  ArrowIcon,
  Modal,
  StatusModal,
  TokenPanel,
} from "@curiodao/capital-dex-ui-kit";
import { SwapService } from "@curiodao/capital-dex-sdk/polkadot";
import { SwapParameters } from "capital-dex-parachain-sdk/build/sdk-swap/swap-parameters";
import { ApiPromise } from "@polkadot/api";
import { getBlockExplorerUrl } from "../../../../../common/_evm/libs/getBlockExplorerUrl";
import { InjectedExtension } from "@polkadot/extension-inject/types";
import classes from "./TransactionModal.module.css";
import { SwapInfoCard } from "../SwapInfoCard";
import { IToken } from "@curiodao/capital-dex-sdk";

interface ITransactionModal {
  inputFrom: string;
  token0: IToken;
  token1: IToken;
  swapParams: SwapParameters;
  api: ApiPromise;
  account: string;
  injector: InjectedExtension;
  onCloseButtonClick: () => void;
}

export const TransactionModal = ({
  inputFrom,
  token0,
  token1,
  swapParams,
  api,
  account,
  injector,
  onCloseButtonClick,
}: ITransactionModal) => {
  const [status, setStatus] = useState<Status>("loading");
  const [isReview, setReview] = useState(true);
  const [tx, setTx] = useState<string>("");
  const [confirmedSwapParams, setConfirmedSwapParams] = useState({
    ...swapParams,
  } as SwapParameters);

  const swap = async () => {
    setReview(false);
    const confirmedSwapParams = {
      ...swapParams,
    } as SwapParameters;
    setConfirmedSwapParams(confirmedSwapParams);

    const swapService = new SwapService(api);
    try {
      const unsub = await swapService
        .swap([token0, token1], confirmedSwapParams)
        .signAndSend(account, { signer: injector.signer }, ({ status }) => {
          console.log(status);
          if (status.isInBlock || status.isFinalized) {
            setTx(status.asInBlock.toString());
            setStatus("done");
            unsub();
          }
        });
    } catch (e) {
      console.error(e);
      setStatus("error");
    }
  };
  return (
    <Modal title="Review swap" onCloseButtonClick={onCloseButtonClick}>
      {isReview ? (
        <div className={classes.ConfirmModal}>
          <div className={classes.Info}>
            <TokenPanel
              token0={token0}
              value={parseFloat(inputFrom).toFixed(4)}
              title="From"
              bordered
            />

            <TokenPanel
              token0={token1}
              value={parseFloat(swapParams.output.balance.toString()).toFixed(
                4,
              )}
              title="To"
              bordered
            />
            <div className={classes.CenterIcon}>
              <ArrowIcon />
            </div>
          </div>
          <SwapInfoCard tokens={[token0, token1]} swapParams={swapParams} />
          <button className="btn block" onClick={swap}>
            Confirm swap
          </button>
        </div>
      ) : (
        <StatusModal
          status={status}
          action="swap"
          token0={token0}
          token1={token1}
          token0value={inputFrom}
          token1value={confirmedSwapParams.output.balance.toString()}
          transactionLink={getBlockExplorerUrl(tx)}
          isSwap
        />
      )}
    </Modal>
  );
};
