import { useEffect, useState } from "react";
import { useTokenBalance } from "../../Tokens/_parachain/hooks/useTokenBalance";
import { useWalletContext } from "../../../common/context/Wallet";
import { getTokenInfoBySymbol } from "../../Tokens";
import { StakingService } from "@curiodao/capital-dex-sdk/polkadot";

export const useStakingBalanceInfo = () => {
  const [staked, setStaked] = useState<bigint | undefined>();
  const [locked, setLocked] = useState<bigint | undefined>();
  const [loading, setLoading] = useState(true);

  const { account, api } = useWalletContext();
  const { balance } = useTokenBalance(getTokenInfoBySymbol("CGT"));

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (account && balance !== "-" && api) {
        (async () => {
          const staking = new StakingService(api, account);
          const staked = await staking.staked();
          setLoading(true);

          if (staked && staked.value.amount) {
            setStaked(staked.value.amount.toBigInt());
            setLoading(false);
          } else {
            setStaked(0n);
            setLoading(false);
          }
          const unstaking = await staking.unstaking();
          let lockedCount = 0n;
          if (unstaking && !unstaking.isEmpty) {
            unstaking.forEach((value) => {
              value ? (lockedCount = lockedCount + value.toBigInt()) : "";
            });
            setLocked(lockedCount);
          } else {
            setLocked(0n);
          }
        })();
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [api, account, balance]);

  return {
    balance,
    staked,
    locked,
    maxStake:
      balance !== undefined && locked !== undefined
        ? BigInt(balance) + locked
        : 0n,
    loading,
  };
};
