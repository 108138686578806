import { IToken } from "@curiodao/capital-dex-sdk";
import { ChainId } from "@uniswap/sdk";

export const customEth = (isTestnet: boolean): IToken => {
  return {
    name: "NEON",
    logoUri:
      "https://raw.githubusercontent.com/neonlabsorg/token-list/main/assets/wrapped-neon-logo.svg",
    symbol: "NEON",
    decimals: 18,
    chainId: isTestnet ? ChainId.NEON_DEVNET : ChainId.NEON,
    address: "",
    isNative: true,
  };
};
