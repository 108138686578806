import classes from "./Pool.module.css";
import { Pair, ShevronIcon } from "@curiodao/capital-dex-ui-kit";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { IFarmPool } from "../../interfaces/IFarmPool";

export const Pool = ({ pool }: { pool: IFarmPool }) => {
  const { token0, token1 } = pool;

  return (
    <Link
      className="card gap-16"
      to={`/pools/add/${token0.symbol}-${token1.symbol}`}
    >
      <div className={classNames(classes.Wrapper, "space-between")}>
        <Pair
          name={`${token0.symbol}-${token1.symbol}`}
          icon0={token0.logoUri}
          icon1={token1.logoUri}
          row
        />
        <div className={classes.Values}>
          <p className="p2">
            APY: <span className="semi">150%</span>
          </p>
          <ShevronIcon />
        </div>
      </div>
    </Link>
  );
};
