import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const mainnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.AURORA,
    name: "Aurora Mainnet",
    rpcUrl: "https://mainnet.aurora.dev",
    currency: "ETH",
    explorerUrl: "https://aurorascan.dev",
  },
  cgt: "0xcc84668Daae56F9f2ef907ce79c8ca0D4fDB12A7",
  whitelist: "0x94ceD2eB6AB0C43dd0CEBA7D0389858729D140A3",
  multicall: "0x211dA8112F2bD1c493a4F9158b9Aa1fa7342a8E8",
  router: "0xAF141A9F7E0627455bE33c7aF69bcDB77Ffb08C9",
  blocksPerYear: 10512000,
  farming: [
    {
      masterChef: "0xB88AF58B511Cb378673A8709E42dA0bF013162fa",
      rewardToken: "CGT",
      rewardPerMonth: 50000,
      pools: [
        {
          pid: 0,
          token0Symbol: "CGT",
          token1Symbol: "DAI",
          pairAddress: "0xfA8Ff45590d3E6664344e5f3677a43f1dD5f7B91",
        },
        {
          pid: 1,
          token0Symbol: "CGT",
          token1Symbol: "AURORA",
          pairAddress: "",
        },
      ],
    },
    {
      masterChef: "0xEF8E153cdbd532C0f9922C168E305B0707f7AC80",
      rewardToken: "AURORA",
      rewardPerMonth: 50000,
      pools: [
        {
          pid: 0,
          token0Symbol: "CGT",
          token1Symbol: "DAI",
          pairAddress: "0x48C5D6199B5f5309aF10ECe77A5B76e3be647b39",
        },
        {
          pid: 1,
          token0Symbol: "CGT",
          token1Symbol: "AURORA",
          pairAddress: "0x7281291F560fb9BC3abb6d31df7c7e7bD6500548",
        },
      ],
    },
  ],
};
