export const FOOTER_LINKS = [
  {
    name: "Privacy policy",
    path: "docs/Capital_DEX_privacy_policy.pdf",
  },
  {
    name: "Capital DEX disclaimer",
    path: "docs/Capital_DEX_disclaimer.pdf",
  },
  {
    name: "CurioDAO disclaimer",
    path: "docs/CurioDAO_website_disclaimer.pdf",
  },
  // {
  //   name: "How to switch network",
  //   path: "",
  // },
];
