import React from "react";
import classes from "./LandingHeader.module.css";
import { Logo } from "@curiodao/capital-dex-ui-kit";
import { Link } from "react-router-dom";

export const LandingHeader = (): JSX.Element => {
  return (
    <div className={classes.HeaderWrapper}>
      <header className={classes.Header}>
        <div className={classes.LogoBox}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <Link to="/swap" className="btn medium">
          Launch app
        </Link>
      </header>
    </div>
  );
};
