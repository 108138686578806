import { IToken } from "@curiodao/capital-dex-sdk";
import cgtToken from "../../assets/img/icons/cgt-token.svg";
//import basilisk from "../../assets/img/tokens/basilisk.svg";
import bitcoin from "../../assets/img/tokens/bitcoin.svg";
import val from "../../assets/img/tokens/val-icon.svg";

export const PARACHAIN_TOKENS_LIST: IToken[] = [
  {
    name: "CGT Token",
    logoUri: cgtToken,
    symbol: "CGT",
    decimals: 18,
    chainId: 0,
    address: "",
  },
  {
    name: "Dai Stablecoin",
    logoUri:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png",
    symbol: "DAI",
    decimals: 18,
    chainId: 0,
    address: "",
  },
  {
    name: "USDC",
    logoUri:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
    symbol: "USDC",
    decimals: 6,
    chainId: 0,
    address: "",
  },
  // {
  //   name: "Basilisk",
  //   symbol: "BSX",
  //   logoUri: basilisk,
  //   decimals: 12,
  //   chainId: 0,
  //   address: "",
  //   foreignAsset: 0,
  // },
  {
    name: "Sora Validator Token",
    logoUri: val,
    symbol: "VAL",
    decimals: 18,
    chainId: 0,
    address: "",
    onlyTestnet: true,
    assetId: 2,
  },
];

export const BTC: IToken = {
  name: "Bitcoin",
  symbol: "BTC",
  decimals: 18,
  logoUri: bitcoin,
  chainId: 0,
  address: "",
};
