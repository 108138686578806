import React from "react";
import classes from "./Liquidity.module.css";
import liquidityImage from "../../assets/liquidity-image.png";
import { Link } from "react-router-dom";

export const LiquiditySection = (): JSX.Element => {
  return (
    <section className={classes.Section}>
      <div className={classes.Content}>
        <div className={classes.LeftBlock}>
          <div className={classes.TextBlock}>
            <h1 className="h1">Liquidity pools</h1>
            <p className="p1">
              Put your funds to work by providing liquidity. When you add
              liquidity to a pool, you can receive a share of its trading volume
              and potentially snag extra rewards when there are incentives
              involved!
            </p>
          </div>
          <Link to="/pools" className="btn medium">
            Explore liquidity
          </Link>
        </div>
        <div className={classes.ImageBlock}>
          <img src={liquidityImage} alt="Liquidity sample" />
        </div>
      </div>
    </section>
  );
};
