import { useEffect } from "react";
import { useNetworkContext } from "../../../modules/Network";
import { useTokenBalance } from "../../../modules/Tokens/_evm/hooks/useTokenBalance";
import { FaucetService } from "@curiodao/capital-dex-sdk/evm";
import { isTestnet } from "../../../core/isTesnet";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";

export const useSkaleFuelBalance = () => {
  const { network, core } = useNetworkContext();
  const { address } = useWeb3ModalAccount();

  const { balance: skaleBalance } = useTokenBalance(
    network === "skale" ? core?.customEth : undefined,
  );

  useEffect(() => {
    if (network === "skale" && core && address) {
      if (BigInt(skaleBalance) < 10000000000000n) {
        (async () => {
          const faucetService = new FaucetService(
            isTestnet,
            core.networkCredentials.rpcUrl,
          );
          await faucetService.getSFuel(address);
        })();
      }
    }
  }, [address, core, network, skaleBalance]);

  return { skaleBalance: network === "skale" ? skaleBalance : undefined };
};
