import { IPage } from "../../common/interfaces/IPage";
import { AddLiquidity } from "../../modules/Pools";
import { AddLiquidity as AddLiquidityTon } from "../../modules/Pools/_ton/components/AddLiquidity";
import { useParams } from "react-router-dom";
import { AddEthLiquidity } from "../../modules/Pools/_evm/components/AddEthLiquidity";
import { useNetworkContext } from "../../modules/Network";

export const AddLiquidityPage = ({ isParachain, isTon }: IPage) => {
  const { pair } = useParams();
  const { core } = useNetworkContext();

  return isParachain ? (
    <AddLiquidity pair={pair} />
  ) : isTon ? (
    <AddLiquidityTon pair={pair} />
  ) : core ? (
    <AddEthLiquidity pair={pair} />
  ) : (
    <div />
  );
};
