import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { FarmsBalanceCard } from "@curiodao/capital-dex-ui-kit";
import React from "react";
import { usePendingHarvest } from "../../hooks/usePendingHarvest";
import { useTokenBalance } from "../../../../Tokens/_parachain/hooks/useTokenBalance";
import { ApiPromise } from "@polkadot/api";
import { getTokenInfoBySymbol } from "../../../../Tokens";
import Skeleton from "react-loading-skeleton";

interface BalanceProps {
  api: ApiPromise | undefined;
  account: Account;
  tokenSymbol: string;
}

export const Balance = ({ api, account, tokenSymbol }: BalanceProps) => {
  const token = getTokenInfoBySymbol(tokenSymbol);
  const { balance, loading: balancesLoading } = useTokenBalance(token);
  const { pendingHarvest, loading: harvestLoading } = usePendingHarvest(
    api,
    account,
    token,
  );

  return (
    <FarmsBalanceCard
      title={`Your balance ${tokenSymbol}`}
      value={
        balancesLoading ? (
          <Skeleton height={50} count={1} />
        ) : (
          getShowBalance(balance, token?.decimals)
        )
      }
      text="Pending harvest"
      balance={
        harvestLoading ? (
          <Skeleton height={19} width={60} count={1} />
        ) : (
          (pendingHarvest?.dsp ?? "-")
        )
      }
      tokenIcon={token?.logoUri ?? ""}
    />
  );
};
