import React, { useLayoutEffect } from "react";
import { HeroSection } from "./components/HeroSection";
import { LiquiditySection } from "./components/LiquiditySection";
import { FarmingSection } from "./components/FarmingSection";
import { StakingSection } from "./components/StakingSection";
import { ChainsSection } from "./components/ChainsSection";
import { DaoSection } from "./components/DaoSection";
import { RwaTokensSection } from "./components/RwaTokensSection";
import { PartnersSection } from "./components/PartnersSection";
import { Footer } from "../../modules/Footer";
import { LandingHeader } from "./components/LandingHeader";
import { BitcoinSection } from "./components/BitcoinSection";
import { useLocation } from "react-router-dom";
import { Banner } from "../../components/Banner";

export const LandingPage = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    if (location.hash && !location.hash.includes("tgWebAppData")) {
      const element = document.querySelector(location.hash);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div style={{ background: "white" }}>
      <Banner />
      <LandingHeader />
      <HeroSection />
      <LiquiditySection />
      <FarmingSection />
      <StakingSection />
      <ChainsSection />
      <DaoSection />
      <RwaTokensSection />
      <BitcoinSection />
      <PartnersSection />
      <Footer isLanding />
    </div>
  );
};
