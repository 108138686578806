import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const mainnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.BSC,
    name: "BNB Smart Chain",
    rpcUrl: "https://bsc-dataseed.binance.org/",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
  },
  cgt: "0x3D04EDC843e74935C09F54cc4b2fe1870E347AC9",
  whitelist: "0x1Da4933B725Afc12C6cCA017d71bBb06d5b096Ef",
  multicall: "0x211dA8112F2bD1c493a4F9158b9Aa1fa7342a8E8",
  router: "0xAF141A9F7E0627455bE33c7aF69bcDB77Ffb08C9",
  blocksPerYear: 10512000,
  farming: [
    {
      masterChef: "0xB88AF58B511Cb378673A8709E42dA0bF013162fa",
      rewardToken: "CGT",
      pools: [
        {
          pid: 0,
          token0Symbol: "",
          token1Symbol: "",
          pairAddress: "",
        },
        {
          pid: 1,
          token0Symbol: "",
          token1Symbol: "",
          pairAddress: "",
        },
      ],
    },
  ],
};
