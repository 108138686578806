import { useState } from "react";
import { SettingsService } from "../services/Settings.service";
import { ISettings } from "../interfaces/ISettings";

export const useSettings = () => {
  const [service] = useState(() => new SettingsService());
  const [settings, setSettings] = useState<ISettings>(service.settings);

  return {
    settings: settings,
    setSettings: (settings: ISettings) => {
      setSettings((prevState) => ({ ...prevState, ...settings }));
      service.setSettings(settings);
    },
    uniswapSettings: service.getSettings(),
  };
};
