import { useEffect, useState } from "react";
import { SwapService } from "../services/swap.service";
import { IToken } from "@curiodao/capital-dex-sdk";
import { ITrade } from "../interfaces/ITrade";
import { formatUnits } from "ethers";

export const useSimulateSwap = (
  token0: IToken | undefined,
  token1: IToken | undefined,
  amount: string,
) => {
  const [trade, setTrade] = useState<ITrade | undefined>();
  const [loading, setLoading] = useState(false);
  const [noLiquidity, setNoLiquidity] = useState(false);

  useEffect(() => {
    (async () => {
      if (token0 && token1 && amount.length) {
        setNoLiquidity(false);
        setLoading(true);
        const swapService = new SwapService();
        const data = await swapService.getExpectedOutputs(
          token0,
          token1,
          amount,
        );
        if (data) {
          const { out, priceImpact, minReceived } = data;
          const swapRate =
            Number(formatUnits(out.jettonToReceive, token1.decimals)) /
            Number(amount);
          setNoLiquidity(out?.jettonToReceive === 0n);
          setTrade({
            min_ask_units: minReceived.toFixed(6),
            ask_units: out.jettonToReceive.toString(),
            swap_rate: swapRate.toString(),
            fee_units: "",
            fee_percent: "0.3",
            offer_units: "",
            pool_address: "",
            router_address: "",
            price_impact: priceImpact.toFixed(3),
            slippage_tolerance: "0.001",
          });
          setLoading(false);
        }
        setLoading(false);
      }
    })();
  }, [amount, token0, token1]);

  return { trade, loading, noLiquidity };
};
