import classes from "./Networks.module.css";
import {
  DropdownPopup,
  IconWithName,
  Pair,
} from "@curiodao/capital-dex-ui-kit";
import { useEffect, useRef, useState } from "react";
import { NetworkService, useNetworkContext } from "../../../Network";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import nymIcon from "../../../../assets/networks/nym.svg";
import { useSearchParams } from "react-router-dom";

export const Networks = () => {
  const [, setSearchParams] = useSearchParams();
  const [networkOpen, setNetworkOpen] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const { network, setNetwork } = useNetworkContext();
  const [networkService] = useState(() => new NetworkService());
  const { list } = networkService;
  const { walletProvider } = useWeb3ModalProvider();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        networkOpen &&
        ref.current &&
        !(ref.current as HTMLDivElement).contains(event.target as Node)
      ) {
        setNetworkOpen(false);
      }
    };
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [setNetworkOpen, networkOpen]);

  useEffect(() => {
    setActiveIdx(networkService.getListNumbByNetwork(network));
  }, [network, networkService]);

  const switchNetwork = async (networkId: string, chainId?: number) => {
    if (chainId && walletProvider) {
      await networkService.switchNetwork(networkId, walletProvider);
    }
    setSearchParams({ chain: networkId });
    setNetwork(networkId);
    setNetworkOpen(false);
  };

  return (
    <div className={classes.NetworkIndicator} ref={ref}>
      {list[activeIdx]?.nym ? (
        <Pair
          icon0={list[activeIdx]?.icon}
          icon1={nymIcon}
          name={list[activeIdx]?.name}
          size={24}
          onClick={() => setNetworkOpen(!networkOpen)}
          isActive={networkOpen}
          isSelect
        />
      ) : (
        <IconWithName
          icon={list[activeIdx]?.icon}
          title={list[activeIdx]?.name}
          onClick={() => setNetworkOpen(!networkOpen)}
          isActive={networkOpen}
          isSelect
        />
      )}
      {networkOpen && (
        <DropdownPopup activeIdx={activeIdx}>
          {list.map((network) =>
            network.nym ? (
              <Pair
                key={network.id}
                icon0={network.icon}
                icon1={nymIcon}
                name={network.name}
                onClick={() => switchNetwork(network.id, network.chainId)}
                size={24}
              />
            ) : (
              <IconWithName
                key={network.id}
                icon={network.icon}
                title={network.name}
                onClick={
                  network.disabled
                    ? undefined
                    : () => switchNetwork(network.id, network.chainId)
                }
                disabled={network.disabled}
              />
            ),
          )}
        </DropdownPopup>
      )}
    </div>
  );
};
