import React from "react";
import classNames from "classnames";
import classes from "./Settings.module.css";
import { Tooltip, Switch, Input } from "@curiodao/capital-dex-ui-kit";
import { ISettings } from "../../interfaces/ISettings";

export const Settings = ({
  settings,
  setSettings,
}: {
  settings: ISettings;
  setSettings: (settings: ISettings) => void;
}) => {
  return (
    <div className={classNames(classes.SettingsCard, "card bordered gap-16")}>
      <div className={classes.Setting}>
        <div className={classes.Row}>
          <p className="p3">Slippage tolerance</p>
          <Tooltip text="Your transaction will revert if the price changes unfavorably by more than this percentage." />
        </div>
        <div className={classes.Row}>
          {["1", "5", "10"].map((percent) => (
            <button
              key={percent}
              className={classNames(
                "menu-item",
                settings.slippage === percent ? "active" : "",
              )}
              onClick={() => setSettings({ ...settings, slippage: percent })}
            >
              {percent}%
            </button>
          ))}
          <div className={classNames("space-between", classes.SlipageValue)}>
            <p className="p3">0.5</p>
            <p className="p3">%</p>
          </div>
        </div>
      </div>
      <div className={classes.Setting}>
        <div className={classes.Row}>
          <p className="p3">Transaction deadline</p>
          <Tooltip text="Your transaction will revert if it is pending for more than this long." />
        </div>
        <div className={classes.Row}>
          <Input>
            <input
              placeholder="20"
              value={settings.deadline}
              type="number"
              step={1}
              onChange={(e) =>
                setSettings({ ...settings, deadline: Number(e.target.value) })
              }
            />
          </Input>
          <p className="p3">minutes</p>
        </div>
      </div>
      <div className={classes.Setting}>
        <div className={classes.Row}>
          <p className="p3">Expert mode</p>
          <Tooltip text="Bypasses confirmation modals and allows high slippage trades. Use at your own risk." />
        </div>
        <Switch>
          <input
            type="checkbox"
            checked={settings.expertMode}
            onChange={(e) => {
              setSettings({ ...settings, expertMode: e.target.checked });
            }}
            disabled
          />
        </Switch>
      </div>
    </div>
  );
};
