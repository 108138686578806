import { useEffect, useState } from "react";
import { useNetworkContext } from "../../../Network";
import { formatUnits } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { FarmsService } from "@curiodao/capital-dex-sdk/evm";

export const useFarmTotalSupply = () => {
  const [info, setInfo] = useState<string>();
  const { core } = useNetworkContext();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (core?.cgt) {
        try {
          setLoading(true);
          const farmsService = new FarmsService(core.cgt);
          const totalSupply = await farmsService.getTotalSupply(
            core.networkCredentials.rpcUrl,
            walletProvider,
          );

          setInfo(formatUnits(totalSupply));
          setLoading(false);
        } catch (error) {
          console.error("Error", error);
        }
      }
    })();
  }, [core, walletProvider]);

  return { info, loading };
};
