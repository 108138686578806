/* 
* Automatically generated by cra-envs.
* If you wish to declare a new environment variable declare it in the .env file (prefixed by REACT_APP_)
* then run 'npx generate-env-getter' at the root of your project.
* This file will be updated.
*/
import { getEnvVarValue } from "cra-envs";

export const envNames = [
    "TESTNET",
    "EVM",
    "CURIO_PARACHAIN",
    "ETH",
    "NEON",
    "BOBA",
    "SKALE",
    "AURORA",
    "BSC",
    "NYM",
    "TON",
    "DEFAULT_NETWORK",
    "PARACHAIN_RPC_URL",
    "TON_CENTER_KEY",
    "API_ENDPOINT",
    "ETH_TOKEN_LIST",
    "INFURA_ID",
    "ALCHEMY",
    "WALLET_CONNECT_PROJECT_ID"
] as const;

export type EnvName = typeof envNames[number];

let env: Record<EnvName, string> | undefined = undefined;

export function getEnv() {

    if (env === undefined) {
        env = {} as Record<EnvName, string>;
        for (const envName of envNames) {
            env[envName] = getEnvVarValue(envName);
        }
    }

    return env;

}
