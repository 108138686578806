import { Modal, Processing, TokenPanel } from "@curiodao/capital-dex-ui-kit";
import { formatUnits, parseUnits } from "ethers";
import { useState } from "react";
import classes from "./StakingModal.module.css";
import { useStake } from "../../hooks/useStake";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import { useAllowance } from "../../../../Tokens";
import { useNetworkContext } from "../../../../Network";
import { IFarmPool } from "@curiodao/capital-dex-sdk/evm";

interface StakingModalProps {
  farmPool: IFarmPool;
  balance: bigint;
  isDeposit?: boolean;
  onCloseButtonClick: () => void;
  staked: bigint;
}

export const StakingModal = ({
  farmPool,
  balance,
  isDeposit,
  onCloseButtonClick,
  staked,
}: StakingModalProps) => {
  const { address } = useWeb3ModalAccount();
  const { core } = useNetworkContext();
  const [value, setValue] = useState("");
  const [isPending, setPending] = useState(false);
  const { stake, unstake } = useStake(farmPool);
  const { isAllowance, approve, isApproving } = useAllowance(
    value,
    farmPool.masterChef,
    {
      name: "",
      logoUri: "",
      symbol: "",
      decimals: 18,
      address: farmPool.lpAddress,
      chainId: 0,
    },
  );

  const handleAction = async () => {
    try {
      if (address && core && value.length) {
        setPending(true);
        if (isDeposit) {
          await stake(parseUnits(value));
          setPending(false);
          onCloseButtonClick();
        } else {
          await unstake(parseUnits(value));
          setPending(false);
          onCloseButtonClick();
        }
      }
    } catch (e) {
      console.error(e);
      setPending(false);
    }
  };

  return (
    <Modal
      title={`${isDeposit ? "Deposit" : "Withdraw"} ${farmPool.token0.symbol}-${farmPool.token1.symbol} CLP`}
      onCloseButtonClick={onCloseButtonClick}
    >
      <div className={classes.Wrapper}>
        <TokenPanel
          token0={farmPool.token0}
          token1={farmPool.token1}
          value={value}
          tokenBalance={
            isDeposit
              ? parseFloat(formatUnits(balance)).toFixed(4)
              : (parseFloat(formatUnits(staked)).toFixed(4) ?? "0")
          }
          setValue={setValue}
        />
        <button
          className="btn block"
          disabled={
            !(
              !!value.length &&
              (isDeposit
                ? parseUnits(value) <= balance
                : parseUnits(value) <= staked)
            ) ||
            isPending ||
            isApproving
          }
          onClick={() => {
            if (isDeposit && !isAllowance) {
              approve(value);
            } else {
              handleAction();
            }
          }}
        >
          {isPending ? (
            <Processing />
          ) : isApproving ? (
            "Approving..."
          ) : !value.length ? (
            "Enter amount"
          ) : (
              isDeposit
                ? parseUnits(value) > balance
                : parseUnits(value) > staked
            ) ? (
            "Amount is too high"
          ) : !isAllowance && isDeposit ? (
            "Approve"
          ) : (
            `Confirm ${isDeposit ? "deposit" : "withdraw"}`
          )}
        </button>
      </div>
    </Modal>
  );
};
