import { DEX, pTON } from "@ston-fi/sdk";
import { SenderArguments } from "@ton/core";
import { IToken } from "@curiodao/capital-dex-sdk";
import { ROUTER } from "../../../../config/router";
import { formatUnits, parseUnits } from "ethers";
import { tonClient } from "../../../../config/clients";

export class SwapService {
  private proxyTon = new pTON.v1();

  calculatePriceImpact(
    amount: string,
    reserved: bigint,
    decimals: number,
  ): number {
    return (
      (parseFloat(amount) /
        (parseFloat(formatUnits(reserved, decimals)) + parseFloat(amount))) *
      100
    );
  }

  calculateMinReceived(
    amountOut: bigint,
    slippageTolerance: number,
    decimals: number,
  ) {
    const amount = parseFloat(formatUnits(amountOut, decimals));
    return amount * (1 - slippageTolerance);
  }

  calculateFee() {}

  async getExpectedOutputs(token0: IToken, token1: IToken, amount: string) {
    try {
      const slippageTolerance = 0.001;
      const lpAddress = await ROUTER.getPoolAddress({
        token0: token0.walletAddress ?? "",
        token1: token1.walletAddress ?? "",
      });
      const pool = tonClient.open(DEX.v1.Pool.create(lpAddress.toRawString()));
      const data = await pool.getPoolData();
      const reserve0 =
        data.token0WalletAddress.toRawString() === token0.walletAddress
          ? data.reserve0
          : data.reserve1;
      // const reserve1 =
      //   data.token0WalletAddress.toRawString() === token0.walletAddress
      //     ? data.reserve1
      //     : data.reserve0;
      const priceImpact = this.calculatePriceImpact(
        amount,
        reserve0,
        token0.decimals,
      );
      console.log(priceImpact);
      const out = await pool.getExpectedOutputs({
        amount: parseUnits(amount, token0.decimals).toString(),
        jettonWallet: token0.walletAddress ?? "",
      });

      const minReceived = this.calculateMinReceived(
        out.jettonToReceive,
        slippageTolerance,
        token1.decimals,
      );

      console.log(out.protocolFeePaid.toString(), out.refFeePaid.toString());
      return { out, priceImpact, minReceived };
    } catch (e) {
      console.error(e);
    }
  }

  async getSwapTxParams(
    token0: IToken,
    token1: IToken,
    amount: string,
    accountId: string,
  ): Promise<SenderArguments | undefined> {
    try {
      const data = {
        userWalletAddress: accountId,
        offerAmount: amount,
        minAskAmount: "1",
      };

      if (!token0.isNative && !token1.isNative) {
        return ROUTER.getSwapJettonToJettonTxParams({
          ...data,
          offerJettonAddress: token0.address,
          askJettonAddress: token1.address,
        });
      } else if (token0.isNative) {
        return ROUTER.getSwapTonToJettonTxParams({
          ...data,
          proxyTon: this.proxyTon,
          askJettonAddress: token1.address,
        });
      } else {
        return ROUTER.getSwapJettonToTonTxParams({
          ...data,
          offerJettonAddress: token0.address,
          proxyTon: this.proxyTon,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
}
