import { useEffect, useState } from "react";
import { IToken } from "@curiodao/capital-dex-sdk";
import { usePositions } from "./usePositions";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { IPosition } from "../interfaces/IPosition";

export const usePoolByTokens = (
  token0: IToken,
  token1: IToken,
  accountId: string,
) => {
  const [pool, setPool] = useState<IPosition | undefined>();
  const { tokenList } = useTokenListContext();
  const { pools } = usePositions(accountId, tokenList);

  useEffect(() => {
    if (pools) {
      (async () => {
        const pool = pools.find(
          (pool) =>
            (pool.token0?.address === token0.address &&
              pool.token1?.address === token1.address) ||
            (pool.token0?.address === token1.address &&
              pool.token1?.address === token0.address),
        );
        setPool(pool);
      })();
    }
  }, [pools, token0.address, token1.address]);

  return { pool };
};
