import { isTestnet } from "../../../../core/isTesnet";
import { PARACHAIN_TOKENS_LIST } from "../../../../contexts/TokenList/constants";

const MAINNET_POOLS = [
  [PARACHAIN_TOKENS_LIST[0], PARACHAIN_TOKENS_LIST[1]],
  [PARACHAIN_TOKENS_LIST[0], PARACHAIN_TOKENS_LIST[2]],
];

const TESTNET_POOLS = [
  ...MAINNET_POOLS,
  [PARACHAIN_TOKENS_LIST[0], PARACHAIN_TOKENS_LIST[3]],
];

export const POOlS = isTestnet ? TESTNET_POOLS : MAINNET_POOLS;
