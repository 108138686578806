import { IToken } from "@curiodao/capital-dex-sdk";
import { ChainId } from "@uniswap/sdk";

export const customEth = (isTestnet: boolean): IToken => {
  return {
    name: "SKALE Gas Token",
    logoUri: "",
    symbol: "sFUEL",
    decimals: 18,
    chainId: isTestnet ? ChainId.SKALE_TESTNET : ChainId.SKALE,
    address: "",
    isNative: true,
  };
};
