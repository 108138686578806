import { Modal } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import classes from "./GetFuelModal.module.css";
import { FaucetService } from "@curiodao/capital-dex-sdk/evm";
import { isTestnet } from "../../core/isTesnet";
import { useState } from "react";

export const GetFuelModal = ({
  balance,
  onCloseButton,
  rpcLink,
  address,
}: {
  balance: string;
  onCloseButton: () => void;
  rpcLink: string;
  address: string;
}) => {
  const [status, setStatus] = useState("review");

  const getSFuel = async () => {
    setStatus("loading");
    const faucetService = new FaucetService(isTestnet, rpcLink);
    await faucetService.getSFuel(address);
    setStatus("done");
  };

  return (
    <Modal title="Get sFUEL" onCloseButtonClick={onCloseButton}>
      <div className={classNames(classes.Inner, "modal-inner")}>
        <p className="p1 semi">
          You&apos;ll need sFUEL (SKALE&apos;s gas token with no market value)
          for transactions on the Europa SKALE chain. You can top up your sFUEL
          balance using the faucet.
        </p>
        <p className="p2 regular color-gray">Balance sFUEL: {balance}</p>
        <button
          className="btn block"
          onClick={status === "review" ? getSFuel : undefined}
          disabled={status !== "review"}
        >
          {status === "review"
            ? "Get sFuel"
            : status === "loading"
              ? "Loading"
              : "Done"}
        </button>
      </div>
    </Modal>
  );
};
