import { useEffect, useState } from "react";
import { isEthereum, useNetworkContext } from "../../../Network";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { FarmService, IFarmPool } from "@curiodao/capital-dex-sdk/evm";

export const useAPY = (pool: IFarmPool) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { core, network } = useNetworkContext();

  const [apy, setApy] = useState<number | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (core?.networkCredentials.rpcUrl) {
      const farmService = new FarmService(pool);
      setLoading(true);

      farmService
        .getOldAPY(
          isEthereum(network),
          core.blocksPerYear,
          core?.networkCredentials.rpcUrl,
          walletProvider,
        )
        .then((v) => {
          setApy(v);
        });
      setLoading(false);
    }
  }, [
    core?.blocksPerYear,
    core?.networkCredentials.rpcUrl,
    network,
    pool,
    walletProvider,
  ]);

  return { apy, loading };
};
