import { useEffect, useState } from "react";
import { BTreeMap, u128, u64 } from "@polkadot/types-codec";
import { useWalletContext } from "../../../common/context/Wallet";
import { useBlock } from "../../../common/hooks/useBlock";
import {
  StakingService,
  SystemInfoService,
} from "@curiodao/capital-dex-sdk/polkadot";

export const useStakingStatistics = () => {
  const [rewardRate, setRewardRate] = useState<bigint | undefined>();
  const [stakingRate, setStakingRate] = useState<bigint | undefined>();
  const [claimPending, setClaimPending] = useState(false);
  const [unlockPending, setUnlockPending] = useState(false);
  const [unstaking, setUnstaking] = useState<BTreeMap<u64, u128>>();
  const [currentBlock, setCurrentBlock] = useState<u64>();
  const { account, injector, api } = useWalletContext();
  const [unclaimedRewards, setUnclaimedRewards] = useState<
    bigint | undefined
  >();
  const block = useBlock();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (account && injector && api) {
      (async () => {
        setLoading(true);
        const staking = new StakingService(api, account);
        const systemInfo = new SystemInfoService(api);
        const { delegatorRewardRate, delegatorStakingRate } =
          await systemInfo.stakingRates();
        setRewardRate(delegatorRewardRate);
        setStakingRate(delegatorStakingRate);
        const unstaking = await staking.unstaking();
        // TODO i donna why on pipeline work u32, but on pc u64
        setUnstaking(unstaking);

        const currentBlock = await systemInfo.currentBlock();
        setCurrentBlock(currentBlock);

        const unclaimedStakingRewards = await staking.unclaimedStakingRewards();
        setUnclaimedRewards(unclaimedStakingRewards.toBigInt());
        setLoading(false);
      })();
    }
  }, [block, account, injector, api]);

  const claim = async () => {
    if (account && injector && api) {
      setClaimPending(true);
      const systemInfo = new SystemInfoService(api);
      try {
        const unsub = await systemInfo
          .claim()
          .signAndSend(
            account,
            { signer: injector.signer },
            ({ status, isError }) => {
              console.log(isError);
              if (status.isFinalized || status.isInBlock) {
                setClaimPending(false);
                unsub();
              }
            },
          );
      } catch (e) {
        setClaimPending(false);
      }
    }
  };

  const unlock = async () => {
    if (account && injector && api) {
      setUnlockPending(true);
      const staking = new StakingService(api, account);
      try {
        const unsub = await staking
          .unlock()
          .signAndSend(
            account,
            { signer: injector.signer },
            ({ status, isError }) => {
              console.log(isError);
              if (status.isFinalized || status.isInBlock) {
                setUnlockPending(false);
                unsub();
              }
            },
          );
      } catch (e) {
        setUnlockPending(false);
      }
    }
  };

  return {
    claim,
    rewardRate,
    stakingRate,
    unclaimedRewards,
    unstaking,
    currentBlock,
    unlock,
    claimPending,
    unlockPending,
    loading,
  };
};
