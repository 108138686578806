import { useEffect, useState } from "react";
import { IPool } from "../interfaces/pool";
import { useWalletContext } from "../../../../common/context/Wallet";
import { PoolsService } from "@curiodao/capital-dex-sdk/polkadot";
import { POOlS } from "../constants/pools";

export const usePools = () => {
  const [pools, setPools] = useState<IPool[]>([]);
  const { api, account } = useWalletContext();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      if (account && api) {
        const poolsService = new PoolsService(api, account);
        setLoading(true);
        const pools = await poolsService.getPools(POOlS);
        setPools(pools);
        setLoading(false);
      }
    })();
  }, [account, api]);

  return { pools, loading };
};
