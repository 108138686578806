import classes from "./Pools.module.css";
import { Pool } from "../Pool";
import { useTokenListContext } from "../../../../../contexts/TokenList";

export const Pools = () => {
  const { tokenList } = useTokenListContext();
  return tokenList[0] && tokenList[1] && tokenList[2] ? (
    <div className={classes.Wrapper}>
      <Pool
        pool={{
          token0: tokenList[0],
          token1: tokenList[1],
          rewardToken: tokenList[0],
        }}
      />
      <Pool
        pool={{
          token0: tokenList[0],
          token1: tokenList[2],
          rewardToken: tokenList[0],
        }}
      />
    </div>
  ) : null;
};
