import { useMutation, useQuery } from "@tanstack/react-query";
import { TokenService } from "@curiodao/capital-dex-sdk/evm";
import { useBlock } from "../../../../common/hooks/useBlock";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { parseUnits } from "ethers";
import { useState } from "react";
import { IToken } from "@curiodao/capital-dex-sdk";

export const useAllowance = (
  value: string,
  spender?: string,
  token?: IToken,
  setIsPending?: (v: boolean) => void,
) => {
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const block = useBlock();
  const [hasApproved, setHasApproved] = useState(false);

  const { data: allowance = 0n, isLoading } = useQuery({
    queryKey: ["allowance", address, spender, token?.symbol, block.toString()],
    queryFn: async () => {
      if (address && walletProvider && token && spender && !token.isNative) {
        const tokens = new TokenService(token, walletProvider);
        return await tokens.getAllowance(address, spender);
      }
      return 0n;
    },
    enabled: !!address && !!spender && !!token && !!walletProvider,
    staleTime: 5 * 1000,
    refetchInterval: 15000,
  });

  const { mutate: approve, isPending: isApproving } = useMutation({
    mutationFn: async (amount: string) => {
      if (address && walletProvider && token && spender) {
        const tokens = new TokenService(token, walletProvider);
        const tx = await tokens.approve(
          spender,
          walletProvider,
          parseUnits(amount, token.decimals).toString(),
        );

        await tx.wait();
        setHasApproved(true);
      }
    },
    onMutate: () => {
      if (setIsPending) setIsPending(true);
    },
    onSuccess: () => {
      if (setIsPending) setIsPending(false);
    },
    onError: () => {
      if (setIsPending) setIsPending(false);
    },
  });

  return {
    approve,
    isAllowance: token?.isNative
      ? true
      : hasApproved ||
        (value.length && parseUnits(value, token?.decimals) <= allowance),
    isApproving,
    loading: isLoading,
  };
};
