import { ISettings } from "../interfaces/ISettings";
import { Percent } from "@uniswap/sdk";
import { IUniswapSetting } from "../interfaces/IUniswapSettings";

export class SettingsService {
  private local = "capital-dex-swap-settings";
  public settings: ISettings = {
    slippage: "5",
    deadline: 20,
    expertMode: false,
  };

  constructor() {
    const local = localStorage.getItem(this.local);
    if (local) {
      const savedSettings: ISettings = JSON.parse(local);
      this.settings = { ...this.settings, ...savedSettings };
    }
  }

  setSettings(settings: ISettings) {
    this.settings = { ...this.settings, ...settings };
    localStorage.setItem(this.local, JSON.stringify(settings));
  }

  getSettings(): IUniswapSetting {
    return {
      slippage: new Percent(this.settings.slippage, "1000"),
      deadline: Math.floor(Date.now() / 1000) + 60 * this.settings.deadline,
      expertMode: this.settings.expertMode,
    };
  }
}
