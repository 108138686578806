import polkadot from "../../../assets/img/wallets/polkadot.svg";
import talisman from "../../../assets/img/wallets/talisman.svg";
import subwallet from "../../../assets/img/wallets/subWallet.svg";

export const providers = [
  {
    name: "Polkadot{.js}",
    icon: polkadot,
    metaName: "polkadot-js",
  },
  {
    name: "Talisman",
    icon: talisman,
    metaName: "talisman",
  },
  {
    name: "SubWallet",
    icon: subwallet,
    metaName: "subwallet-js",
  },
];
