import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const mainnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.NEON,
    name: "Neon EVM Mainnet",
    rpcUrl: "https://neon-proxy-mainnet.solana.p2p.org",
    currency: "NEON",
    explorerUrl: "https://neonscan.org",
  },
  etherNotInTokenList: true,
  cgt: "0xC1eD606683a3f89317d64BDA602628d68a0B4b24",
  whitelist: "0x1Da4933B725Afc12C6cCA017d71bBb06d5b096Ef",
  multicall: "0x211dA8112F2bD1c493a4F9158b9Aa1fa7342a8E8",
  router: "0xAF141A9F7E0627455bE33c7aF69bcDB77Ffb08C9",
  blocksPerYear: 31536000,
  farming: [
    {
      rewardPerMonth: 0,
      rewardToken: "CGT2",
      masterChef: "0xbb8343122C79D706AC251005C8D14887dda3bd3D",
      pools: [
        {
          pid: 0,
          pairAddress: "0x301F3047056976c03cd3BC885c46014Ad8dec1a5",
          token0Symbol: "JSOL",
          token1Symbol: "CGT2",
        },
        {
          pid: 1,
          pairAddress: "0xf013FD8b44798f844c9bAD09D67Da79881222A7F",
          token0Symbol: "CGT2",
          token1Symbol: "USDC",
        },
      ],
    },
  ],
};
