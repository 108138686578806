import React from "react";
import { SwapIcon, InfoRow } from "@curiodao/capital-dex-ui-kit";
import { SwapParameters } from "capital-dex-parachain-sdk/build/sdk-swap/swap-parameters";
import { IToken } from "@curiodao/capital-dex-sdk";

export const SwapInfoCard = ({
  tokens,
  swapParams,
}: {
  tokens: (IToken | undefined)[];
  swapParams: SwapParameters;
}) => {
  return (
    <div className="card bordered">
      <InfoRow
        title="Rates"
        value={
          <>
            1 {tokens[0]?.symbol} ={" "}
            {(
              swapParams.output.balance.toNumber() /
              swapParams.input.balance.toNumber()
            ).toFixed(4)}{" "}
            {tokens[1]?.symbol} <SwapIcon />
          </>
        }
      />
      <InfoRow
        title="Minimum received"
        info="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
        value={`${(parseFloat(swapParams.output.balance.toString()) - parseFloat(swapParams.exchangeFee.toString())).toFixed(4)} ${tokens[1]?.symbol}`}
      />
      <InfoRow
        title="Price impact"
        info="The difference between the market price and estimated price due to trade size."
        value={`${parseFloat(swapParams.priceImpact.toNumber().toFixed(4)) * 100}%`}
      />
      <InfoRow
        title="Liquidity provider fee"
        info="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive."
        value={`${swapParams.exchangeFee.toString()} ${tokens[0]?.symbol}`}
      />
    </div>
  );
};
