import classes from "./FarmCards.module.css";
import {
  Processing,
  TokenInput,
  useModalByName,
} from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { useWalletContext } from "../../../../../common/context/Wallet";
import { usePoolByTokens } from "../../../../Pools/_parachain/hooks/usePool";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { useStaked } from "../../hooks/useStaked";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { Link } from "react-router-dom";
import { useHarvestReward } from "../../hooks/useHarvestReward";
import { StakingModal } from "../StakingModal";
import { useState } from "react";
import { IFarmPool } from "@curiodao/capital-dex-sdk/polkadot";
import Skeleton from "react-loading-skeleton";

export const FarmCards = ({ farmPool }: { farmPool: IFarmPool }) => {
  const { account, api, injector } = useWalletContext();
  const pool = usePoolByTokens(farmPool.token0, farmPool.token1, account);
  const { staked } = useStaked(pool, api, account);
  const { rewards, loading } = useHarvestReward(
    api,
    account,
    pool,
    farmPool.rewardTokens,
  );
  const { changeModal, modal, closeModal } = useModalByName();
  const [pendingHarvest, setPendingHarvest] = useState(false);

  const harvest = async () => {
    if (pool && account && injector && api) {
      setPendingHarvest(true);
      const farms = new FarmsService(api);
      const unsub = await farms
        .harvest(pool)
        .signAndSend(account, { signer: injector.signer }, ({ status }) => {
          if (status.isFinalized || status.isInBlock) {
            setPendingHarvest(false);
            unsub();
          }
        });
    }
  };

  return (
    <>
      <div className={classNames(classes.Earned, "card")}>
        <p className="p1 semi">{farmPool.rewardTokens.join("+")} Earned</p>
        {loading ? (
          <Skeleton height={70} count={1} />
        ) : (
          <div className="card bordered">
            {rewards.map((reward, index) => (
              <TokenInput
                key={index}
                token0={reward.token}
                value={reward.value.dsp}
              />
            ))}
          </div>
        )}
        <button
          className="btn block"
          onClick={harvest}
          disabled={
            !(
              (rewards[0] && rewards[0].value.bn > 0n) ||
              (rewards[1] && rewards[1].value.bn > 0n)
            ) || pendingHarvest
          }
        >
          {pendingHarvest ? <Processing /> : "Harvest"}
        </button>
      </div>
      <div className="card">
        <p className="p1 semi">{farmPool.id} CLP tokens staked</p>
        {loading ? (
          <Skeleton height={97} count={1} />
        ) : (
          <div className="card bordered">
            <TokenInput
              token0={farmPool.token0}
              token1={farmPool.token1}
              value={staked?.dsp ?? "0"}
            />
            <p className="p3">
              Balance {farmPool.id}: {getShowBalance(pool?.userShare)}
            </p>
          </div>
        )}
        {(pool?.userShare === 0n || pool?.userShare === undefined) &&
        (staked?.bn === 0n || staked?.bn === undefined) ? (
          <Link className="btn block" to={`/pools/add/${farmPool.id}`}>
            Add liquidity
          </Link>
        ) : (
          <div className={classes.Btns}>
            {staked?.bn !== undefined && staked?.bn !== 0n && (
              <button
                className="btn secondary block"
                onClick={() => changeModal("withdraw")}
                disabled={modal === "withdraw"}
              >
                {modal === "withdraw" ? <Processing /> : "Unstake"}
              </button>
            )}
            {staked?.bn !== undefined &&
              pool?.userShare !== undefined &&
              staked?.bn !== pool?.userShare && (
                <button
                  className="btn block"
                  onClick={() => changeModal("deposit")}
                  disabled={modal === "deposit"}
                >
                  {modal === "deposit" ? <Processing /> : "Stake"}
                </button>
              )}
          </div>
        )}
      </div>
      {(modal === "deposit" || modal === "withdraw") && pool && (
        <StakingModal
          farmPool={farmPool}
          pool={pool}
          onCloseButtonClick={closeModal}
          isDeposit={modal === "deposit"}
          staked={staked}
        />
      )}
    </>
  );
};
