import { isTestnet } from "../../isTesnet";
import { env } from "../../env";
import { INetworkCore } from "../../../common/_evm/interfaces/INetworkCore";

import eth from "./eth";
import neon from "./neon";
import skale from "./skale";
import boba from "./boba";
import bsc from "./bsc";
import aurora from "./aurora";

let EVM_NETWORKS_CORE: { [key: string]: INetworkCore } = {};

if (env.ETH === "true") {
  EVM_NETWORKS_CORE = {
    [eth.networkId]: {
      ...(isTestnet ? eth.testnet : eth.mainnet),
      name: "Ethereum",
      icon: eth.icon,
    },
  };
}

if (env.NEON === "true") {
  const config = {
    ...(isTestnet ? neon.testnet : neon.mainnet),
    name: "Neon",
    icon: neon.icon,
    customEth: neon.customEth(isTestnet),
  };

  EVM_NETWORKS_CORE = {
    ...EVM_NETWORKS_CORE,
    [neon.networkId]: config,
  };
  if (neon.nym && env.NYM === "true") {
    EVM_NETWORKS_CORE = {
      ...EVM_NETWORKS_CORE,
      [`${neon.networkId}_nym`]: {
        ...config,
        name: "Neon via Nym",
        networkCredentials: {
          ...config.networkCredentials,
          ...neon.nym,
        },
        nym: true,
      },
    };
  }
}

if (env.BOBA === "true") {
  EVM_NETWORKS_CORE = {
    ...EVM_NETWORKS_CORE,
    [boba.networkId]: {
      ...(isTestnet ? boba.testnet : boba.mainnet),
      name: "Boba",
      icon: boba.icon,
    },
  };
}

if (env.SKALE === "true") {
  EVM_NETWORKS_CORE = {
    ...EVM_NETWORKS_CORE,
    [skale.networkId]: {
      ...(isTestnet ? skale.testnet : skale.mainnet),
      name: "Europa",
      icon: skale.icon,
      customEth: skale.customEth(isTestnet),
      etherNotInTokenList: true,
    },
  };
}

if (env.BSC === "true") {
  EVM_NETWORKS_CORE = {
    ...EVM_NETWORKS_CORE,
    [bsc.networkId]: {
      ...(isTestnet ? bsc.testnet : bsc.mainnet),
      name: "BNB smart chain",
      icon: bsc.icon,
      customEth: bsc.customEth(isTestnet),
    },
  };
}

if (env.AURORA === "true") {
  EVM_NETWORKS_CORE = {
    ...EVM_NETWORKS_CORE,
    [aurora.networkId]: {
      ...(isTestnet ? aurora.testnet : aurora.mainnet),
      name: "Aurora",
      icon: aurora.icon,
    },
  };
}

// If you need add network conditional
const isEthereum = (networkId: string) => networkId === eth.networkId;

export { EVM_NETWORKS_CORE, isEthereum };
