import classNames from "classnames";
import { Processing, SmallLoading } from "@curiodao/capital-dex-ui-kit";
import classes from "./Statistics.module.css";
import React, { useEffect, useState } from "react";
import cgtToken from "../../../../assets/img/icons/cgt-token.svg";
import { useStakingStatistics } from "../../hooks/useStakingStatistics";
import { useStakingBalanceInfo } from "../../hooks/useStakingBalanceInfo";
import { ILockedBlock } from "../../interfaces/ILockedBlock";
import { toHuman } from "../../../../common/libs/support";
import { getShowBalance } from "../../../../common/libs/getShowBalance";
import Skeleton from "react-loading-skeleton";

export const StatisticsBlock = () => {
  const {
    claim,
    rewardRate,
    stakingRate,
    unclaimedRewards,
    unstaking,
    currentBlock,
    unlock,
    claimPending,
    unlockPending,
    loading: pendingLoading,
  } = useStakingStatistics();

  const {
    balance,
    staked,
    locked,
    maxStake,
    loading: stakingLoading,
  } = useStakingBalanceInfo();
  const [showLocked, setShowLocked] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<number>();
  const [amountInfoBlocks, setAmountInfoBlocks] = useState<ILockedBlock[]>([]);

  useEffect(() => {
    const startTime = new Date().getTime() / 1000;
    setStartTime(startTime);
  }, []);

  useEffect(() => {
    if (unstaking && !unstaking.isEmpty && currentBlock && startTime) {
      setShowLocked(true);

      const updateTimer = () => {
        const currentTime = new Date().getTime() / 1000;
        const newAmountInfoBlocks: ILockedBlock[] = [];

        unstaking.forEach((value, key) => {
          const timeInSeconds =
            startTime +
            (parseFloat(key.toString()) - parseFloat(currentBlock.toString())) *
              12 -
            currentTime;
          const days = Math.floor(timeInSeconds / (60 * 60 * 24));
          const hours = Math.floor(
            (timeInSeconds % (60 * 60 * 24)) / (60 * 60),
          );
          const minutes = Math.floor((timeInSeconds % (60 * 60)) / 60);
          const seconds = Math.floor(timeInSeconds % 60);

          const time = `${days}d, ${hours}h, ${minutes}m, ${seconds}s`;
          const newAmountInfoBlock = {
            value: value.toString(),
            currentBlock: currentBlock,
            key: key,
            timeInSeconds: timeInSeconds,
            time: time,
          };

          newAmountInfoBlocks.push(newAmountInfoBlock);
        });

        setAmountInfoBlocks(newAmountInfoBlocks);
      };

      const intervalId = setInterval(updateTimer, 1000);

      return () => clearInterval(intervalId);
    } else {
      setShowLocked(false);
    }
  }, [unstaking, currentBlock, startTime]);

  return (
    <div className={classNames("card gap-16", classes.Wrapper)}>
      <p className="p1 semi">Statistics</p>
      <div className={classes.CardWrap}>
        <div className={classNames("info-card gap-16", classes.InnerCard)}>
          <div className={classes.InfoRow}>
            <div className={classes.InfoBox}>
              <p className="p3">Balance CGT</p>
              <div className={classes.TokenBox}>
                <div className={classes.TokenImage}>
                  <img src={cgtToken} />
                </div>
                {stakingLoading ? (
                  <Skeleton height={29} width={70} count={1} />
                ) : (
                  <p className="h4">{getShowBalance(balance)}</p>
                )}
              </div>
            </div>
            <div className={classes.InfoBox}>
              <p className="p3">Staked CGT</p>
              <div className={classes.TokenBox}>
                <div className={classes.TokenImage}>
                  <img src={cgtToken} />
                </div>
                {stakingLoading ? (
                  <Skeleton height={29} width={70} count={1} />
                ) : (
                  <p className="h4">{getShowBalance(staked)}</p>
                )}
              </div>
            </div>
          </div>
          <div className={classes.InfoRow}>
            <div className={classes.InfoBox}>
              <p className="p3">Pending rewards CGT</p>
              <div className={classes.TokenBox}>
                <div className={classes.TokenImage}>
                  <img src={cgtToken} />
                </div>
                {pendingLoading ? (
                  <Skeleton height={29} width={70} count={1} />
                ) : (
                  <p className="h4">{getShowBalance(unclaimedRewards)}</p>
                )}
              </div>
            </div>
            <button
              className={classNames("btn small", classes.ClaimButton)}
              disabled={!unclaimedRewards || claimPending}
              onClick={claim}
            >
              {claimPending ? (
                <div className={classes.Processing}>
                  <SmallLoading />
                  <p className={classNames(classes.Title)}>
                    Claiming rewards...
                  </p>
                </div>
              ) : (
                "Claim rewards"
              )}
            </button>
          </div>
        </div>

        <div className={classNames("info-card", classes.InnerCard)}>
          <div className={classes.InfoRow}>
            <div className={classes.InfoBox}>
              <p className="p3">Stakeable CGT</p>
              <div className={classes.TokenBox}>
                <div className={classes.TokenImage}>
                  <img src={cgtToken} />
                </div>
                {stakingLoading ? (
                  <Skeleton height={29} width={70} count={1} />
                ) : (
                  <p className="h4">{getShowBalance(maxStake)}</p>
                )}
              </div>
            </div>
          </div>
          <div className={classes.TextInfoRow}>
            <p className="p3">
              Transferable:{" "}
              {stakingLoading ? (
                <Skeleton height={19} width={50} />
              ) : (
                getShowBalance(balance)
              )}
            </p>
            <p className="p3">
              Locked:{" "}
              {stakingLoading ? (
                <Skeleton height={19} width={50} />
              ) : (
                getShowBalance(locked)
              )}
            </p>
          </div>
        </div>
      </div>

      {showLocked && (
        <div className={classes.CardWrap}>
          <div className={classNames("info-card", classes.LockedCard)}>
            <p className="p3">Locked amounts</p>
            <div className={classes.LockedInfos}>
              {amountInfoBlocks.map((item) => (
                <div className={classes.LochedInfo} key={item.key.toString()}>
                  <div className={classes.LockedTokenBlock}>
                    <div className={classes.LockedToken}>
                      <img src={cgtToken} />
                      <span className="p3 semi">
                        {toHuman(item.value.toString())}
                      </span>
                    </div>
                  </div>
                  <div className={classes.LockedRightSide}>
                    {item.currentBlock ? (
                      parseFloat(item.key.toString()) <
                        parseFloat(item.currentBlock.toString()) ||
                      item.timeInSeconds <= 0 ? (
                        <button
                          className="btn small secondary"
                          onClick={unlock}
                          disabled={unlockPending}
                        >
                          {unlockPending ? <Processing /> : "Withdraw"}
                        </button>
                      ) : (
                        <span className="p3">{item.time}</span>
                      )
                    ) : (
                      <span className="p3">-</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className={classes.BottomBlock}>
        <p className="p3 semi">System info</p>
        <div className={classes.DoubleInfos}>
          <p className="p3">
            Reward rate:{" "}
            {pendingLoading ? (
              <Skeleton height={16} width={50} count={1} />
            ) : (
              `${rewardRate ? parseFloat(toHuman(rewardRate, 16)).toFixed(2) : "-"}%`
            )}
          </p>
          <p className="p3">
            Staked:{" "}
            {pendingLoading ? (
              <Skeleton height={16} width={50} count={1} />
            ) : (
              `${getShowBalance(stakingRate ? stakingRate * 100n : undefined)}%`
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
