import {
  useWeb3ModalAccount,
  useWeb3ModalError,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../modules/Network";

export const useWeb3Active = () => {
  const { chainId } = useNetworkContext();
  const { address, chainId: chainIdFromProvider } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { error } = useWeb3ModalError();

  console.log(error);

  return chainIdFromProvider !== chainId
    ? { address: undefined, walletProvider: undefined }
    : { address, walletProvider };
};
