import { useEffect, useState } from "react";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { IPosition } from "../../_parachain/interfaces/IPosition";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { PoolsService, getUniswapToken } from "@curiodao/capital-dex-sdk/evm";
import { IToken } from "@curiodao/capital-dex-sdk";

export const usePoolInfoByTokens = (
  account: string | undefined,
  token0: IToken | undefined,
  token1: IToken | undefined,
): { position: IPosition | undefined; loading: boolean } => {
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId } = useNetworkContext();
  const { tokenList } = useTokenListContext();
  const [poolInfo, setPoolInfo] = useState<IPosition | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        if (account && walletProvider && token0 && token1) {
          const poolsService = new PoolsService();
          setLoading(true);

          const tokenA = getUniswapToken(token0, tokenList);
          const tokenB = getUniswapToken(token1, tokenList);

          if (tokenA && tokenB) {
            const position = await poolsService.getUserPosition(
              [tokenA, tokenB],
              tokenList,
              account,
              chainId,
              walletProvider,
            );
            setPoolInfo(position);
            setLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching pool info by tokens", error);
      }
    })();
  }, [account, chainId, tokenList, walletProvider, token0, token1]);

  return { position: poolInfo, loading };
};
