import { useEffect, useState } from "react";
import { IPool } from "../interfaces/pool";
import { useWalletContext } from "../../../../common/context/Wallet";
import { IPoolBalances } from "../interfaces/IPoolBalances";
import { useBlock } from "../../../../common/hooks/useBlock";
import { PoolsService } from "@curiodao/capital-dex-sdk/polkadot";
import { IToken } from "@curiodao/capital-dex-sdk";

export const usePool = (
  pool: IPool | undefined,
  account: string | null | undefined,
) => {
  const [poolInfo, setPoolInfo] = useState<IPoolBalances>();
  const { api } = useWalletContext();

  useEffect(() => {
    if (pool && account && api) {
      (async () => {
        const poolService = new PoolsService(api, account);
        const poolInfo = await poolService.getPoolBalancesByUser(pool);
        setPoolInfo(poolInfo);
      })();
    }
  }, [pool, account, api]);

  return { poolInfo };
};

export const usePoolByTokens = (
  token0: IToken,
  token1: IToken,
  account: string | undefined,
) => {
  const [pool, setPool] = useState<IPool>();
  const { api } = useWalletContext();
  const block = useBlock();

  useEffect(() => {
    (async () => {
      if (account && api) {
        const poolService = new PoolsService(api, account);
        const { tokenA, tokenB, dexShare, pair } = poolService.getTokens(
          token0,
          token1,
        );
        const userShare = await poolService.getUserShare(token0, token1);
        setPool({
          pair,
          dexShare,
          userShare,
          tokenA,
          tokenB,
        });
      }
    })();
  }, [account, api, token0, token1, block]);

  return pool;
};
