import { useEffect, useState } from "react";
import classes from "./Banner.module.css";

export const Banner = () => {
  const [timer, setTimer] = useState("");

  const formatTime = (time: number) => {
    const days = Math.floor(time / 60 / 60 / 24);
    const hours = Math.floor((time - days * 60 * 60 * 24) / 60 / 60);
    const minutes = Math.floor(
      (time - days * 60 * 60 * 24 - hours * 60 * 60) / 60,
    );
    const seconds = Math.floor(
      time - days * 60 * 60 * 24 - hours * 60 * 60 - minutes * 60,
    );

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    const daysString = days > 0 ? `${days} days ` : "";

    return `${daysString} ${formattedHours} hours ${formattedMinutes} minutes ${formattedSeconds} seconds`;
  };

  const updateTimer = () => {
    const sec = Math.floor((1731715199000 - new Date().getTime()) / 1000) - 1;
    setTimer(sec > 0 ? formatTime(sec) : "");
  };

  useEffect(() => {
    updateTimer();
    setInterval(() => updateTimer(), 1000);
  }, []);
  return (
    <div className={classes.Banner}>
      <p>
        🔥 JAMTON Mainnet Testing Countdown! Register{" "}
        <a
          href="https://jamton.network"
          target="_blank"
          rel="noreferrer nofollower"
        >
          here
        </a>{" "}
        for exclusive early access before{" "}
        <a
          href="https://devcon.org"
          target="_blank"
          rel="noreferrer nofollower"
        >
          Devcon
        </a>
        .
      </p>
      <p>
        Just {timer} left — be part of the latest in cross-chain DeFi
        innovation! 🚀
      </p>
    </div>
  );
};
