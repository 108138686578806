import { useEffect, useState } from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { IToken } from "@curiodao/capital-dex-sdk";
import { MulticallService } from "@curiodao/capital-dex-sdk/evm";

export const useTokenBalances = (
  tokens: IToken[],
  multicallAddress?: string,
) => {
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [balancesList, setBalancesList] = useState<
    { token: IToken; balance: string }[]
  >([]);
  useEffect(() => {
    if (multicallAddress && walletProvider && address && tokens.length) {
      (async () => {
        const multicall = new MulticallService();

        try {
          const balances = await multicall.getBalances(
            tokens,
            address,
            walletProvider,
            multicallAddress,
          );
          setBalancesList(balances);
        } catch {
          console.log("Error");
          setBalancesList([]);
        }
      })();
    }
  }, [multicallAddress, walletProvider, tokens, address]);
  return balancesList;
};
