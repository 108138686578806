import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const mainnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.MAINNET,
    name: "Mainnet",
    rpcUrl: "https://mainnet.gateway.tenderly.co",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
  },
  cgt: "0xd219F93eab932441eE2c5A41b3353f0497447DF2",
  whitelist: "0xcFC8002c27985410F7a5Df76f418E5F1a460e1eb",
  router: "0xC657723A215114282B9304dd2A20Aa4d6a1bEB24",
  multicall: "0x715D0aE552FE5B04A0C3ee7078Ba2fbb804Cc2A3",
  blocksPerYear: 2336000,
  farming: [
    // chef v1
    {
      masterChef: "0xe8Cc9f640C55f3c5905FD2BBb63C53fb8A3A527d",
      rewardToken: "CGT",
      pools: [
        {
          pid: 1,
          pairAddress: "0x9a7c27F2BfD86001c0E9B5b9096564F64F37439E",
          token0Symbol: "ETH",
          token1Symbol: "CGT",
        },
        {
          pid: 2,
          pairAddress: "0x85323e31bCa3a8da8c5307671DDe878C4bBCDD36",
          token0Symbol: "CUR",
          token1Symbol: "ETH",
        },
        {
          pid: 3,
          pairAddress: "0xB9FcE07dB9737810CbC573E43ba700aA4655b6Bc",
          token0Symbol: "DAI",
          token1Symbol: "CGT",
        },
      ],
    },
    // chef 2
    {
      masterChef: "0xE115e56a5a725E1fEbf17820E8fb5Ad8f17d5cF1",
      rewardToken: "CGT",
      pools: [
        {
          pid: 1,
          pairAddress: "0xf67c990798221fdf41a4e77b6be2ce5c87df771e",
          token0Symbol: "wCT1",
          token1Symbol: "CGT",
        },
        {
          pid: 2,
          pairAddress: "0x6456f0176d27265c8b530aacfc1fdadae3d60b04",
          token0Symbol: "LINK",
          token1Symbol: "CGT",
        },
      ],
    },
  ],
  cgtCurSwapContract: "0x8D51965006A049E3dc8D9Ee2d69f31E1cbF611aA",
  wrapping: ["CT1", "wCT1"],
};
