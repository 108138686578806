import { useState } from "react";
import {
  ArrowIcon,
  Modal,
  StatusModal,
  TokenPanel,
} from "@curiodao/capital-dex-ui-kit";
import classes from "./TransactionModal.module.css";
import { SwapInfoCard } from "../SwapInfoCard";
import { IToken } from "@curiodao/capital-dex-sdk";
import { formatUnits, parseUnits } from "ethers";
import { ITrade } from "../../interfaces/ITrade";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { ROUTER } from "../../../../../config/router";
import { pTON } from "@ston-fi/sdk";
import { isTestnet } from "../../../../../core/isTesnet";

interface ITransactionModal {
  inputFrom: string;
  token0: IToken;
  token1: IToken;
  swapParams: ITrade;
  accountId: string;
  onCloseButtonClick: () => void;
}

export const TransactionModal = ({
  inputFrom,
  token0,
  token1,
  swapParams,
  accountId,
  onCloseButtonClick,
}: ITransactionModal) => {
  const [status, setStatus] = useState<Status>("loading");
  const [isReview, setReview] = useState(true);
  const [tonConnectUI] = useTonConnectUI();

  const swap = async () => {
    setReview(false);
    try {
      const data = {
        userWalletAddress: accountId,
        offerAmount: parseUnits(inputFrom, token0.decimals).toString(),
        minAskAmount: "1",
        queryId: Date.now(),
      };
      let txParams;
      if (token0.isNative) {
        txParams = await ROUTER.getSwapTonToJettonTxParams({
          ...data,
          proxyTon: new pTON.v1(),
          askJettonAddress: token1.address ?? "",
        });
      } else if (token1.isNative) {
        txParams = await ROUTER.getSwapJettonToTonTxParams({
          ...data,
          proxyTon: new pTON.v1(),
          offerJettonAddress: token0.address ?? "",
        });
      } else {
        txParams = await ROUTER.getSwapJettonToJettonTxParams({
          ...data,
          offerJettonAddress: token0.address ?? "",
          askJettonAddress: token1.address ?? "",
        });
      }
      console.log(txParams);
      await tonConnectUI.sendTransaction({
        validUntil: Date.now() + 1000000,
        messages: [
          {
            address: txParams.to.toString(),
            amount: txParams.value.toString(),
            payload: txParams.body?.toBoc().toString("base64"),
          },
        ],
      });
      setStatus("done");
    } catch (e) {
      console.error(e);
      setStatus("error");
    }
  };
  return (
    <Modal title="Review swap" onCloseButtonClick={onCloseButtonClick}>
      {isReview ? (
        <div className={classes.ConfirmModal}>
          <div className={classes.Info}>
            <TokenPanel
              token0={token0}
              value={parseFloat(inputFrom).toFixed(4)}
              title="From"
              bordered
            />

            <TokenPanel
              token0={token1}
              value={formatUnits(swapParams.ask_units, token1.decimals)}
              title="To"
              bordered
            />
            <div className={classes.CenterIcon}>
              <ArrowIcon />
            </div>
          </div>
          <SwapInfoCard tokens={[token0, token1]} trade={swapParams} />
          <button className="btn block" onClick={swap}>
            Confirm swap
          </button>
        </div>
      ) : (
        <StatusModal
          status={status}
          action="swap"
          token0={token0}
          token1={token1}
          token0value={inputFrom}
          token1value={formatUnits(swapParams.ask_units, token1.decimals)}
          transactionLink={`https://${isTestnet ? "testnet." : ""}tonviewer.com/${accountId}`}
          isSwap
        />
      )}
    </Modal>
  );
};
