import { IToken } from "@curiodao/capital-dex-sdk";
import { IJetton } from "../interfaces/IJetton";

export const jettonsToTokens = (jettons: IJetton[]): IToken[] =>
  jettons.map((jetton) => ({
    name: jetton.display_name === "Proxy TON" ? "TON" : jetton.display_name,
    symbol:
      jetton.symbol.length > 0
        ? jetton.symbol === "pTON"
          ? "TON"
          : jetton.symbol
        : jetton.display_name,
    decimals: Number(jetton.decimals),
    chainId: 0,
    address: jetton.contract_address,
    logoUri: jetton.image_url,
    walletAddress: jetton.wallet_address,
    isNative: jetton.symbol === "pTON",
  }));
