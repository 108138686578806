import { useState } from "react";
import {
  InfoRow,
  Modal,
  StatusModal,
  SwapIcon,
  TokenPanel,
} from "@curiodao/capital-dex-ui-kit";
import { PoolsService } from "@curiodao/capital-dex-sdk/polkadot";
import { ApiPromise } from "@polkadot/api";
import { getBlockExplorerUrl } from "../../../../../common/_evm/libs/getBlockExplorerUrl";
import { InjectedExtension } from "@polkadot/extension-inject/types";
import classes from "./AddTransactionModal.module.css";
import { IToken } from "@curiodao/capital-dex-sdk";

interface ITransactionModal {
  token0Amount: string;
  token1Amount: string;
  token0: IToken;
  token1: IToken;
  api: ApiPromise;
  account: string;
  injector: InjectedExtension;
  onCloseButtonClick: () => void;
  rates: string | undefined;
  poolShare: string | undefined;
  lpTokenAmount: string;
}

export const AddTransactionModal = ({
  token0Amount,
  token1Amount,
  token0,
  token1,
  api,
  account,
  injector,
  onCloseButtonClick,
  rates,
  poolShare,
  lpTokenAmount,
}: ITransactionModal) => {
  const [status, setStatus] = useState<Status>("loading");
  const [isReview, setReview] = useState(true);
  const [tx, setTx] = useState<string>("");

  const handle = async () => {
    setReview(false);
    const poolService = new PoolsService(api, account);
    try {
      const method = poolService.add(
        token0,
        token1,
        token0Amount,
        token1Amount,
      );

      const unsub = await method.signAndSend(
        account,
        { signer: injector.signer },
        ({ status }) => {
          if (status.isInBlock || status.isFinalized) {
            setTx(status.asInBlock.toString());
            setStatus("done");
            unsub();
          }
        },
      );
    } catch (e) {
      console.error(e);
      setStatus("error");
    }
  };

  return (
    <Modal title={"Supply"} onCloseButtonClick={onCloseButtonClick}>
      {isReview ? (
        <div className={classes.Confirm}>
          <TokenPanel
            token0={token0}
            token1={token1}
            value={lpTokenAmount}
            title="Receive"
            bordered
          />
          <div className="card bordered">
            <InfoRow
              title="Rates"
              value={
                <>
                  1 {token0.symbol} = {`${rates} ${token1.symbol}`}
                  <SwapIcon />
                </>
              }
            />
            <InfoRow
              title={`${token0.symbol} deposited`}
              value={token0Amount}
            />
            <InfoRow
              title={`${token1.symbol} deposited`}
              value={token1Amount}
            />
            <InfoRow
              title="Share of pool"
              value={`${Number(poolShare) * 100}%`}
            />
          </div>
          <button className="btn block" onClick={handle}>
            Confirm supply
          </button>
        </div>
      ) : (
        <StatusModal
          status={status}
          action={"supply"}
          token0={token0}
          token1={token1}
          token0value={token0Amount.toString()}
          token1value={token1Amount.toString()}
          transactionLink={getBlockExplorerUrl(tx)}
        />
      )}
    </Modal>
  );
};
