import { TelegramIcon, TwitterIcon } from "@curiodao/capital-dex-ui-kit";

export const SOCIAL_LINKS = [
  {
    name: "twitter",
    link: "https://twitter.com/curio_invest",
    icon: <TwitterIcon />,
  },
  {
    name: "telegram",
    link: "https://t.me/CurioInvestCommunity",
    icon: <TelegramIcon />,
  },
];
