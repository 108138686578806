import { useEffect, useState } from "react";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { ApiPromise } from "@polkadot/api";
import { displayValue, toHuman } from "../../../../common/libs/support";

export const useTotalIssuance = (api: ApiPromise | undefined) => {
  const [totalIssuance, setTotalIssuance] = useState<Web3Num | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (api) {
      setLoading(true);
      const farms = new FarmsService(api);
      farms.getTotalIssuance().then((res) => {
        setTotalIssuance({
          str: toHuman(res.toBigInt(), 18),
          dsp: displayValue(res.toBigInt(), 18),
          bn: res.toBigInt(),
        });
      });
      setLoading(false);
    }
  }, [api]);

  return { totalIssuance, loading };
};
