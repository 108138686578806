import classes from "./Pools.module.css";
import { PARACHAIN_POOLS } from "../../constants/parachain-pools";
import { Pool } from "../Pool";

export const Pools = () => {
  return (
    <div className={classes.Wrapper}>
      {PARACHAIN_POOLS.map((pool, idx) => (
        <Pool key={idx} pool={pool} />
      ))}
    </div>
  );
};
