import { IToken } from "@curiodao/capital-dex-sdk";
import { useEffect, useState } from "react";
import { PoolsService } from "../services/pools.service";

type PoolsAmount = {
  amount0: bigint;
  amount1: bigint;
};

export const useSimulateRemove = (
  token0: IToken,
  token1: IToken,
  lpAmount: string,
) => {
  const [poolAmounts, setPoolAmounts] = useState<PoolsAmount | undefined>();

  useEffect(() => {
    if (lpAmount.length) {
      const poolSevice = new PoolsService();
      poolSevice.getExpectedLiquidity(token0, token1, lpAmount).then((res) => {
        if (res) {
          setPoolAmounts(res);
        }
      });
    } else {
      setPoolAmounts(undefined);
    }
  }, [lpAmount, token0, token1]);

  return poolAmounts;
};
