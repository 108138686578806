import { useState, useEffect } from "react";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { ApiPromise } from "@polkadot/api";
import { IPool } from "../../../Pools/_parachain/interfaces/pool";
import { useBlock } from "../../../../common/hooks/useBlock";
import { displayValue, toHuman } from "../../../../common/libs/support";

export const useStaked = (
  pool: IPool | undefined,
  api: ApiPromise | undefined,
  account: Account,
) => {
  const [staked, setStaked] = useState<Web3Num | undefined>();
  const block = useBlock();

  useEffect(() => {
    if (pool && account && api) {
      const farms = new FarmsService(api);
      farms.getStaked(pool, account).then((res) => {
        setStaked({
          dsp: displayValue(res[0].toBigInt()),
          str: toHuman(res[0].toBigInt()),
          bn: res[0].toBigInt(),
        });
      });
    }
  }, [account, api, pool, block]);

  return { staked };
};
