import { useState } from "react";
import classes from "./TransactionModal.module.css";
import {
  ArrowIcon,
  Modal,
  StatusModal,
  TokenPanel,
} from "@curiodao/capital-dex-ui-kit";
import { getBlockExplorerUrl } from "../../../../../common/_evm/libs/getBlockExplorerUrl";
import { SwapService } from "@curiodao/capital-dex-sdk/evm";
import { Eip1193Provider } from "ethers";
import { useNetworkContext } from "../../../../Network";
import { Trade } from "@uniswap/sdk";
import { IUniswapSetting } from "../../interfaces/IUniswapSettings";
import { isWrappingTokens } from "../../../../../common/_evm/libs/isWrappingTokens";
import { SwapInfoCard } from "../SwapInfoCard";
import { IToken } from "@curiodao/capital-dex-sdk";

interface ITransactionModal {
  inputFrom: string;
  token0: IToken;
  token1: IToken;
  walletProvider: Eip1193Provider;
  onCloseButtonClick: () => void;
  account: string;
  trade: Trade;
  uniswapSettings: IUniswapSetting;
  tokens: (IToken | undefined)[];
}

export const TransactionModal = ({
  inputFrom,
  token0,
  token1,
  uniswapSettings,
  trade,
  walletProvider,
  onCloseButtonClick,
  account,
  tokens,
}: ITransactionModal) => {
  const [status, setStatus] = useState<Status>("loading");
  const [isReview, setReview] = useState(true);
  const { core } = useNetworkContext();
  const [tx, setTx] = useState<string>("");

  const handleSwap = async () => {
    setReview(false);
    try {
      if (core?.router && core?.networkCredentials.rpcUrl) {
        const swapService = new SwapService();

        let txHash = "";

        if (isWrappingTokens(token0, token1)) {
          txHash = await swapService.depositOrWithdraw(
            token0,
            token1,
            "1",
            walletProvider,
          );
        } else {
          txHash = await swapService.swap(
            trade,
            core.router,
            walletProvider,
            account,
            uniswapSettings,
          );
        }
        if (txHash) {
          setTx(txHash);
          setStatus("done");
        } else {
          console.log("error while swapping");
          setStatus("error");
        }
      }
    } catch (e) {
      console.error(e);
      setStatus("error");
    }
  };

  return (
    <Modal title="Review swap" onCloseButtonClick={onCloseButtonClick}>
      {isReview ? (
        <div className={classes.ConfirmModal}>
          <div className={classes.Info}>
            <TokenPanel
              token0={token0}
              value={parseFloat(inputFrom).toFixed(4)}
              title="From"
              bordered
            />
            <TokenPanel
              token0={token1}
              value={parseFloat(trade?.outputAmount?.toSignificant(6)).toFixed(
                4,
              )}
              title="To"
              bordered
            />
            <div className={classes.CenterIcon}>
              <ArrowIcon />
            </div>
          </div>
          <SwapInfoCard
            uniswapSettings={uniswapSettings}
            tokens={tokens}
            trade={trade}
          />
          <button className="btn block" onClick={handleSwap}>
            Confirm swap
          </button>
        </div>
      ) : (
        <StatusModal
          status={status}
          action="swap"
          token0={token0}
          token1={token1}
          token0value={inputFrom}
          token1value={trade?.outputAmount?.toSignificant(6)}
          transactionLink={getBlockExplorerUrl(
            tx,
            core?.networkCredentials.explorerUrl,
          )}
          isSwap
        />
      )}
    </Modal>
  );
};
