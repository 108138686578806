import { useEffect, useState } from "react";

export const useBlock = () => {
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTrigger((v) => v + 1);
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return trigger;
};
