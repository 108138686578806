import { IPool } from "../interfaces/IPool";
import { AxiosInstance } from "axios";
import { IToken } from "@curiodao/capital-dex-sdk";
import { IBalance } from "../../../Tokens/_ton/interfaces/IBalance";
import { formatUnits, parseUnits } from "ethers";
import { tonClient } from "../../../../config/clients";
import { ROUTER } from "../../../../config/router";
import { DEX, pTON } from "@ston-fi/sdk";

export class PoolsService {
  async getPositions(
    balances: IBalance[],
    dexClient: AxiosInstance,
  ): Promise<IPool[] | null> {
    try {
      const { data } = await dexClient.get(`/pools`);
      return data.map((pool: IPool) => {
        const balance = balances.find(
          (balance) => balance.jetton.address === pool.address,
        );
        return { ...pool, lp_balance: balance?.balance };
      });
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getPool(token0: IToken, token1: IToken) {
    try {
      const lpAddress = await ROUTER.getPoolAddress({
        token0: token0.walletAddress ?? "",
        token1: token1.walletAddress ?? "",
      });
      const pool = tonClient.open(DEX.v1.Pool.create(lpAddress.toRawString()));
      const data = await pool.getPoolData();
      const jettonData = await pool.getJettonData();
      return {
        pool,
        data,
        totalSupply: jettonData.totalSupply,
      };
    } catch (e) {
      return null;
    }
  }

  async getExpectedTokens(
    token0: IToken,
    token1: IToken,
    input0: string,
    input1: string,
  ) {
    console.log(input1);
    try {
      const data = await this.getPool(token0, token1);
      if (data) {
        const isToken0 =
          data.data.token0WalletAddress.toRawString() === token0.walletAddress;
        const amount0 = parseUnits(input0, token0.decimals);

        const reserve0 = isToken0 ? data.data.reserve0 : data.data.reserve1;
        const reserve1 = isToken0 ? data.data.reserve1 : data.data.reserve0;

        const reserve0Int = parseFloat(formatUnits(reserve0, token0.decimals));
        const reserve1Int = parseFloat(formatUnits(reserve1, token1.decimals));

        const input1Int = (parseFloat(input0) * reserve1Int) / reserve0Int;

        const amount1 = parseUnits(
          input1Int.toFixed(token1.decimals),
          token1.decimals,
        );

        // const lpAddress = await data.pool.getLpAccountAddress();
        // const lpAccount = tonClient.open(
        //   DEX.v1.LpAccount.create(data.lpAddress),
        // );
        // const lpAccountData = await lpAccount.getLpAccountData();
        // console.log(lpAccountData);
        const lpBalance = await data.pool.getExpectedTokens({
          amount0: isToken0 ? amount0 : amount1,
          amount1: isToken0 ? amount1 : amount0,
        });
        console.log(lpBalance, amount0, amount1);
        const userShare = Number(lpBalance) / Number(data.totalSupply);
        return {
          lpBalance: lpBalance.toString(),
          userShare: userShare > 1 ? 0.9999 : userShare,
          input1: amount1,
        };
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getExpectedLiquidity(token0: IToken, token1: IToken, lpAmount: string) {
    const pool = await this.getPool(token0, token1);
    return pool?.pool.getExpectedLiquidity({
      jettonAmount: parseUnits(lpAmount, 9),
    });
  }

  async getProvideLiquidityTxParams(
    token0: IToken,
    token1: IToken,
    token0Amount: string,
    token1Amount: string,
    accountId: string,
  ) {
    try {
      const data = {
        userWalletAddress: accountId,
        minLpOut: "1",
      };

      if (token0.isNative || token1.isNative) {
        const isSendTon = token0.isNative;

        return await Promise.all([
          // deposit 1 TON to the STON/TON pool and get at least 1 nano LP token
          ROUTER.getProvideLiquidityTonTxParams({
            ...data,
            proxyTon: new pTON.v1(),
            sendAmount: parseUnits(
              isSendTon ? token0Amount : token1Amount,
              isSendTon ? token0.decimals : token1.decimals,
            ).toString(),
            otherTokenAddress:
              (isSendTon ? token1.address : token0.address) ?? "",
            queryId: Date.now(),
          }),
          // deposit 0.5 STON to the STON/TON pool and get at least 1 nano LP token
          ROUTER.getProvideLiquidityJettonTxParams({
            ...data,
            sendTokenAddress:
              (isSendTon ? token1.address : token0.address) ?? "",
            sendAmount: parseUnits(
              isSendTon ? token1Amount : token0Amount,
              isSendTon ? token1.decimals : token0.decimals,
            ).toString(),
            otherTokenAddress: new pTON.v1().address,
            queryId: Date.now() + 10000,
          }),
        ]);
      } else {
        const t = await Promise.all([
          ROUTER.getProvideLiquidityJettonTxParams({
            ...data,
            sendTokenAddress: token0.address,
            sendAmount: parseUnits(token0Amount, token0.decimals).toString(),
            otherTokenAddress: token1.address,
            queryId: Date.now(),
          }),
          ROUTER.getProvideLiquidityJettonTxParams({
            ...data,
            sendTokenAddress: token1.address,
            sendAmount: parseUnits(token1Amount, token1.decimals).toString(),
            otherTokenAddress: token0.address,
            queryId: Date.now() + 10000,
          }),
        ]);
        console.log(t);
        return t;
      }
    } catch (e) {
      console.error(e);
    }
  }
}
