import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNetworkContext } from "./useNetworkContext";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { NetworkService } from "../../services/Network.service";

export const useInitNetwork = () => {
  const [searchParams] = useSearchParams();
  const { network, setNetwork, isParachain, isTon, chainId, core } =
    useNetworkContext();
  const { chainId: chainIdFromProvider } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [networkService] = useState(() => new NetworkService());

  // set network from url or get default
  useEffect(() => {
    if (!network || (network && !network.length)) {
      const networkFromUrl = networkService.getUrlNetwork(searchParams);
      if (
        !networkService.isParachainNetwork(networkFromUrl) &&
        walletProvider
      ) {
        networkService.switchNetwork(networkFromUrl, walletProvider);
      }
      setNetwork(networkService.getUrlNetwork(searchParams));
    }
  }, [network, networkService, searchParams, setNetwork, walletProvider]);

  return {
    errorNetwork:
      !isTon &&
      !isParachain &&
      walletProvider &&
      chainId !== chainIdFromProvider
        ? core?.networkCredentials.name
        : null,
  };
};
