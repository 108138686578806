import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const testnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.AURORA_TESTNET,
    name: "Aurora Testnet",
    rpcUrl: "https://testnet.aurora.dev",
    currency: "ETH",
    explorerUrl: "https://testnet.aurorascan.dev",
  },
  cgt: "0xCE50714532b8d139C8A619CCC6617160d9314DF1",
  whitelist: "0x9Adf500B9A5e7C858808990c20421127F44c626d",
  multicall: "0xF8b94e51C35Eb37667D362CB8Ad1fE87874151fC",
  router: "0xCd8A7fE3d81bdce676fAD9072F7C1eB176e69930",
  blocksPerYear: 10512000,
  farming: [
    {
      masterChef: "0xE62f44dd13E16B40477A3782C85b892b21247d76",
      rewardToken: "CGT",
      pools: [
        {
          pid: 0,
          token0Symbol: "CGT",
          token1Symbol: "DAI",
          pairAddress: "0x48C5D6199B5f5309aF10ECe77A5B76e3be647b39",
        },
        {
          pid: 1,
          token0Symbol: "CGT",
          token1Symbol: "AURORA",
          pairAddress: "0x7281291F560fb9BC3abb6d31df7c7e7bD6500548",
        },
      ],
    },
    {
      masterChef: "0x065B862dfac9000886C6e9498b230F8CEaF6C8E0",
      rewardToken: "AURORA",
      pools: [
        {
          pid: 0,
          token0Symbol: "CGT",
          token1Symbol: "DAI",
          pairAddress: "0x48C5D6199B5f5309aF10ECe77A5B76e3be647b39",
        },
        {
          pid: 1,
          token0Symbol: "CGT",
          token1Symbol: "AURORA",
          pairAddress: "0x7281291F560fb9BC3abb6d31df7c7e7bD6500548",
        },
      ],
    },
  ],
};
