import { JSX, useState } from "react";
import classes from "./Staking.module.css";
import { Tabs, Tab, Processing } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import cgtToken from "../../../../assets/img/icons/cgt-token.svg";
import { useStaking } from "../../hooks/useStaking";
import { useRewardRate } from "../../hooks/useRewardRate";
import { useStakingBalanceInfo } from "../../hooks/useStakingBalanceInfo";
import { toHuman, toToken } from "../../../../common/libs/support";
import { isValidInput } from "../../../../common/libs/isValidInput";
import { getShowBalance } from "../../../../common/libs/getShowBalance";
import Skeleton from "react-loading-skeleton";

export const StakingBlock = (): JSX.Element => {
  const [openTab, setOpenTab] = useState<"stake" | "unstake">("stake");
  const [value, setValue] = useState("");

  const { stake, remove, stakePending, removePending } = useStaking();

  const { staked, maxStake, loading: balanceLoading } = useStakingBalanceInfo();

  const {
    minStake,
    rewardRate,
    loading: rewardLoading,
  } = useRewardRate(value, openTab);

  const clean = () => {
    setValue("");
  };

  return (
    <div className={classNames("card gap-16", classes.Wrapper)}>
      <Tabs isFullWidth>
        <Tab active={openTab === "stake"} onClick={() => setOpenTab("stake")}>
          Stake
        </Tab>
        <Tab
          active={openTab === "unstake"}
          onClick={() => setOpenTab("unstake")}
        >
          Unstake
        </Tab>
      </Tabs>
      <div className={classNames(classes.FormCard, "card gray")}>
        <p className="p3 color-gray">{openTab}</p>
        <div className={classes.InputBox}>
          <input
            className={classNames("h3", classes.Input)}
            type="text"
            value={value}
            placeholder="0"
            onChange={(e) => {
              setValue(isValidInput(e.target.value) ? e.target.value : value);
            }}
          />
          <div className={classes.TokenBox}>
            <img src={cgtToken} />
            <p className="p2 semi">CGT</p>
          </div>
        </div>
        <p className="p3 color-gray">
          Balance:{" "}
          {openTab === "stake" ? (
            balanceLoading ? (
              <Skeleton height={19} width={50} count={1} />
            ) : (
              getShowBalance(maxStake)
            )
          ) : balanceLoading ? (
            <Skeleton height={19} width={50} count={1} />
          ) : (
            getShowBalance(staked)
          )}
        </p>
      </div>
      <p className={classNames("p2 color-gray")}>
        Unstaked amounts can only be withdrawn after 7 days
      </p>
      {rewardLoading ? (
        <Skeleton height={52} count={1} />
      ) : (
        <div className={classes.InfoWrap}>
          <div className={classNames("info-card")}>
            <div className={classNames("space-between", classes.CardInner)}>
              <div className={classes.Rewards}>
                <p className="p3 color-gray">Reward rate</p>
                {/* TODO */}
                {/* <Tooltip text="" /> */}
              </div>
              <p className="p3 semi">
                {rewardRate ? toHuman(rewardRate, 16) : "-"}%
              </p>
            </div>
          </div>
        </div>
      )}
      {openTab === "stake" ? (
        <button
          className="btn block"
          onClick={() => stake(value, staked, clean)}
          disabled={
            !parseFloat(value) ||
            !(
              toToken(value) <= maxStake &&
              (toToken(value) >= (minStake ?? 0n) || !!staked)
            ) ||
            stakePending
          }
        >
          {stakePending ? <Processing /> : "Stake"}
        </button>
      ) : (
        <button
          className="btn block"
          onClick={() => remove(value, staked, clean)}
          disabled={
            !parseFloat(value) ||
            !(
              !!staked &&
              !!minStake &&
              toToken(value) <= staked &&
              (staked - toToken(value) >= minStake ||
                staked - toToken(value) === 0n)
            ) ||
            removePending
          }
        >
          {removePending ? <Processing /> : "Unstake"}
        </button>
      )}
    </div>
  );
};
