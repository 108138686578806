import { useWalletContext } from "../../../common/context/Wallet";
import { useState } from "react";
import { SystemInfoService } from "@curiodao/capital-dex-sdk/polkadot";

export const useStaking = () => {
  const { account, injector, api } = useWalletContext();
  const [stakePending, setStakePending] = useState(false);
  const [removePending, setRemovePending] = useState(false);

  const stake = async (amount: string, staked?: bigint, clean?: () => void) => {
    if (account && injector && staked !== undefined && api) {
      const systemInfo = new SystemInfoService(api);
      if (clean) {
        clean();
      }
      setStakePending(true);
      try {
        const unsub = await (
          await systemInfo.stake(amount, staked)
        ).signAndSend(account, { signer: injector.signer }, ({ status }) => {
          if (status.isFinalized || status.isInBlock) {
            setStakePending(false);
            unsub();
          }
        });
      } catch (e) {
        setStakePending(false);
      }
    }
  };

  const remove = async (
    amount: string,
    staked?: bigint,
    clean?: () => void,
  ) => {
    if (account && injector && staked && api) {
      const systemInfo = new SystemInfoService(api);
      if (clean) {
        clean();
      }
      setRemovePending(true);
      try {
        const unsub = await systemInfo
          .remove(amount, staked)
          ?.signAndSend(account, { signer: injector.signer }, ({ status }) => {
            if (status.isFinalized || status.isInBlock) {
              setRemovePending(false);
              if (unsub) {
                unsub();
              }
            }
          });
      } catch (e) {
        setRemovePending(false);
      }
    }
  };

  return {
    stake,
    remove,
    stakePending,
    removePending,
  };
};
