import { useState } from "react";
import { toToken } from "../../../../common/libs/support";
import { useEthTokenBalance } from "../../../Tokens";
import { IToken } from "@curiodao/capital-dex-sdk";
import { getEvmTokenInfoBySymbol } from "@curiodao/capital-dex-sdk/evm";
import { BTC } from "../../../../contexts/TokenList/constants";

export const useTokensPanel = (
  account: string | undefined,
  modal: string,
  inputFrom: string,
  evmTokenList: IToken[],
  pair?: string[],
) => {
  const [tokens, setTokens] = useState<(IToken | undefined)[]>([
    getEvmTokenInfoBySymbol(pair ? pair[0] : "CGT", evmTokenList),
    getEvmTokenInfoBySymbol(pair ? pair[1] : "", evmTokenList),
  ]);

  const { balance: token0Balance } = useEthTokenBalance(tokens[0]);
  const { balance: token1Balance } = useEthTokenBalance(tokens[1]);

  const selectToken = (token: IToken) => {
    setTokens(
      modal === "token-from"
        ? token.name === tokens[1]?.name
          ? [token, tokens[0]]
          : [token, tokens[1]]
        : token.name === tokens[0]?.name
          ? [tokens[1], token]
          : [tokens[0], token],
    );
  };

  return {
    tokens,
    setTokens,
    token0Balance,
    token1Balance,
    selectToken,
    error:
      tokens[0]?.symbol === BTC.symbol || tokens[1]?.symbol === BTC.symbol
        ? "Coming soon"
        : !account
          ? "Connect wallet"
          : !tokens[0] || !tokens[1]
            ? "Select tokens"
            : !inputFrom.length
              ? "Enter amount"
              : toToken(inputFrom, tokens[0]?.decimals) > BigInt(token0Balance)
                ? `Insufficient ${tokens[0]?.symbol} balance`
                : undefined,
  };
};
