import classes from "./PoolEthInfo.module.css";
import { PoolCard, InfoRow } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { IPosition } from "../../../_parachain/interfaces/IPosition";

export const PoolEthInfo = ({ position }: { position: IPosition }) => {
  const {
    token0,
    token1,
    tokenAPooled,
    tokenBPooled,
    userPoolBalance,
    poolShare,
  } = position;

  return (
    <PoolCard
      token0Icon={token0.logoUri}
      token1Icon={token1.logoUri}
      name={`${token0.symbol}-${token1.symbol}`}
      balance={userPoolBalance.toFixed(7)}
      empty={Number(userPoolBalance.toFixed(7)) <= 0}
      href={`/pools/add/${token0.symbol}-${token1.symbol}`}
    >
      <div className={classes.CardInner}>
        <div className={classNames(classes.Info, "card bordered")}>
          <InfoRow
            title={`Pooled ${token0.symbol}`}
            value={tokenAPooled.toFixed(
              token0.decimals < 4 ? token0.decimals : 4,
            )}
          />
          <InfoRow
            title={`Pooled ${token1.symbol}`}
            value={tokenBPooled.toFixed(
              token1.decimals < 4 ? token1.decimals : 4,
            )}
          />
          <InfoRow
            title="Your pool tokens"
            value={`${userPoolBalance.toFixed(7)} ${token0.symbol}-${token1.symbol}`}
          />
          <InfoRow
            title="Your pool share"
            value={`${poolShare.toSignificant(4)}%`}
          />
        </div>
        <div className={classes.Buttons}>
          <Link
            to={`/pools/remove/${token0.symbol}-${token1.symbol}`}
            className="btn secondary block"
          >
            Remove
          </Link>
          <Link
            to={`/pools/add/${position.token0.symbol}-${position.token1.symbol}`}
            className="btn block"
          >
            Add
          </Link>
        </div>
      </div>
    </PoolCard>
  );
};
