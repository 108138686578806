import React from "react";
import classes from "./Farming.module.css";
import moneybox from "../../assets/pig.svg";
import tokens from "../../assets/tokens.svg";
import coinsImage from "../../assets/coins.svg";
import { ShevronIcon } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { Link } from "react-router-dom";

export const FarmingSection = (): JSX.Element => {
  return (
    <section className={classes.Section}>
      <div className={classes.Content}>
        <div className={classes.TopCard}>
          <div className={classes.FarmingImage}>
            <img src={moneybox} alt="Moneybox pig" />
          </div>
          <div className={classes.FarmingTextBlock}>
            <div className={classes.FarmingDescription}>
              <h2 className="h1">Farming CGT</h2>
              <p className="p1">
                Get extra rewards for staking liquidity in farming pools. In
                addition to the standard rewards for liquidity providers as
                exchange fees from traders, farming pools allow you to get
                rewards in Curio Governance Token (CGT) and other tokens.
              </p>
            </div>
            <Link to="/farms" className="btn medium">
              Start now
            </Link>
          </div>
        </div>
        <div className={classes.DoubleCards}>
          <div className={classes.TokensCard}>
            <img src={tokens} alt="tokens" />
          </div>
          <div className={classes.MiniCard}>
            <div className={classes.CoinsImage}>
              <img src={coinsImage} alt="Group of coins" />
            </div>
            <div className={classes.MiniCardInner}>
              <h4 className="h4">What is CGT?</h4>
              <p className="p2">
                Curio Governance Token (CGT) is at the heart of the CurioDAO
                ecosystem and the Curio chain. Buy it, earn it, farm it, spend
                it, stake it... oh yeah, you can even vote with it using OpenGov
                on the Curio chain!
              </p>
              <div className={classes.Links}>
                <a
                  className={classNames("p2 semi", classes.Link)}
                  href="https://capitaldex.exchange/docs/Curio_Governance_Token_CGT.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                  <ShevronIcon />
                </a>
                <a
                  className={classNames("p2 semi", classes.Link)}
                  href="https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Fparachain.curioinvest.com#/referenda"
                  target="_blank"
                  rel="noreferrer"
                >
                  Explore Curio OpenGov
                  <ShevronIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
