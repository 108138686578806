import { useState } from "react";
import { toToken } from "../../../../common/libs/support";
import { IToken } from "@curiodao/capital-dex-sdk";
import { useTonAddress } from "@tonconnect/ui-react";
import { useQuery } from "@tanstack/react-query";
import { JettonsService } from "../../../Tokens";
import { tonDexClient } from "../../../../config/clients";
import { getEvmTokenInfoBySymbol } from "@curiodao/capital-dex-sdk/evm";
import { BTC } from "../../../../contexts/TokenList/constants";

export const useTokensPanel = (
  account: string | undefined,
  modal: string,
  inputFrom: string,
  tokenList: IToken[],
  pair?: string[],
) => {
  const [tokens, setTokens] = useState<(IToken | undefined)[]>([
    pair ? getEvmTokenInfoBySymbol(pair[0], tokenList) : tokenList[0],
    pair ? getEvmTokenInfoBySymbol(pair[1], tokenList) : undefined,
  ]);

  const tonAddress = useTonAddress();

  const { data } = useQuery({
    queryKey: ["fetchBalances", tonAddress],
    queryFn: () => new JettonsService(tonDexClient).fetchBalances(tonAddress),
    staleTime: 5 * 1000,
  });

  const { data: balanceTon } = useQuery({
    queryKey: ["fetchTonBalance", tonAddress],
    queryFn: () => new JettonsService(tonDexClient).fetchBalanceTon(tonAddress),
    staleTime: 5 * 1000,
  });

  const token0Balance =
    (tokens[0]?.isNative
      ? balanceTon
      : data?.find((balance) => balance.jetton.address === tokens[0]?.address)
          ?.balance) ?? "0";
  const token1Balance =
    (tokens[1]?.isNative
      ? balanceTon
      : data?.find((balance) => balance.jetton.address === tokens[1]?.address)
          ?.balance) ?? "0";

  const selectToken = (token: IToken) => {
    setTokens(
      modal === "token-from"
        ? token.name === tokens[1]?.name
          ? [token, tokens[0]]
          : [token, tokens[1]]
        : token.name === tokens[0]?.name
          ? [tokens[1], token]
          : [tokens[0], token],
    );
  };

  return {
    tokens,
    setTokens,
    token0Balance,
    token1Balance,
    selectToken,
    error:
      tokens[0]?.symbol === BTC.symbol || tokens[1]?.symbol === BTC.symbol
        ? "Coming soon"
        : !account
          ? "Connect wallet"
          : !tokens[0] || !tokens[1]
            ? "Select tokens"
            : !inputFrom.length
              ? "Enter amount"
              : toToken(inputFrom, tokens[0]?.decimals) > BigInt(token0Balance)
                ? `Insufficient ${tokens[0]?.symbol} balance`
                : undefined,
  };
};
