import { useState } from "react";
import {
  InfoRow,
  Modal,
  StatusModal,
  SwapIcon,
  TokenPanel,
} from "@curiodao/capital-dex-ui-kit";
import classes from "./AddTransactionModal.module.css";
import { IToken } from "@curiodao/capital-dex-sdk";
import { formatUnits } from "ethers";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { PoolsService } from "../../services/pools.service";
import { isTestnet } from "../../../../../core/isTesnet";

interface ITransactionModal {
  token0Amount: string;
  token1Amount: string;
  token0: IToken;
  token1: IToken;
  accountId: string;
  onCloseButtonClick: () => void;
  rates: string | undefined;
  poolShare: string | undefined;
  lpTokenAmount: string;
}

export const AddTransactionModal = ({
  token0Amount,
  token1Amount,
  token0,
  token1,
  onCloseButtonClick,
  rates,
  poolShare,
  lpTokenAmount,
  accountId,
}: ITransactionModal) => {
  const [status, setStatus] = useState<Status>("loading");
  const [isReview, setReview] = useState(true);
  const [tonConnectUI] = useTonConnectUI();

  const handle = async () => {
    setReview(false);
    try {
      const poolService = new PoolsService();
      const txsParams = await poolService.getProvideLiquidityTxParams(
        token0,
        token1,
        token0Amount,
        token1Amount,
        accountId,
      );

      if (txsParams) {
        await tonConnectUI.sendTransaction({
          validUntil: Date.now() + 1000000,
          messages: [
            {
              address: txsParams[0].to.toString(),
              amount: txsParams[0].value.toString(),
              payload: txsParams[0].body?.toBoc().toString("base64"),
            },
            {
              address: txsParams[1].to.toString(),
              amount: txsParams[1].value.toString(),
              payload: txsParams[1].body?.toBoc().toString("base64"),
            },
          ],
        });
      }
      setStatus("done");
    } catch (e) {
      console.error(e);
      setStatus("error");
    }
  };

  return (
    <Modal title={"Supply"} onCloseButtonClick={onCloseButtonClick}>
      {isReview ? (
        <div className={classes.Confirm}>
          <TokenPanel
            token0={token0}
            token1={token1}
            value={formatUnits(lpTokenAmount, 9)}
            title="Receive"
            bordered
          />
          <div className="card bordered">
            <InfoRow
              title="Rates"
              value={
                <>
                  1 {token0.symbol} = {`${rates} ${token1.symbol}`}
                  <SwapIcon />
                </>
              }
            />
            <InfoRow
              title={`${token0.symbol} deposited`}
              value={token0Amount}
            />
            <InfoRow
              title={`${token1.symbol} deposited`}
              value={token1Amount}
            />
            <InfoRow title="Share of pool" value={`${poolShare}%`} />
          </div>
          <button className="btn block" onClick={handle}>
            Confirm supply
          </button>
        </div>
      ) : (
        <StatusModal
          status={status}
          action={"supply"}
          token0={token0}
          token1={token1}
          token0value={token0Amount.toString()}
          token1value={token1Amount.toString()}
          transactionLink={`https://${isTestnet ? "testnet." : ""}tonviewer.com/${accountId}`}
        />
      )}
    </Modal>
  );
};
