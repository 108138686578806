import { useWalletContext } from "../../../../common/context/Wallet";
import { IconWithName, useModal } from "@curiodao/capital-dex-ui-kit";
import Identicon from "@polkadot/react-identicon";
import { shortAddress } from "../../../../common/libs/shortAddress";
import React from "react";
import { ConnectWalletModal } from "../../../Account/components/ConnectWalletModal";
import ethereumWhite from "../../../../assets/img/networks/ethereum.svg";
import curioParachainWhite from "../../../../assets/img/networks/curio-parachain.white.svg";
import ton from "../../../../assets/networks/ton.svg";
import Jazzicon from "react-jazzicon";
import { useWeb3Modal } from "@web3modal/ethers/react";
import { useWeb3Active } from "../../../../common/_evm/hooks/useWeb3Active";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";

export const Wallet = ({
  isParachain,
  isTon,
}: {
  isParachain: boolean;
  isTon: boolean;
}) => {
  const { account: parachainAccount } = useWalletContext();
  const { isShowing, toggleModal } = useModal();
  const { open } = useWeb3Modal();
  const { address } = useWeb3Active();
  const [tonConnectUI] = useTonConnectUI();
  const tonAddress = useTonAddress();

  return (
    <>
      {isParachain &&
        (parachainAccount ? (
          <IconWithName
            icon={
              <Identicon value={parachainAccount} size={24} theme="polkadot" />
            }
            title={shortAddress(parachainAccount)}
            onClick={toggleModal}
          />
        ) : (
          <button className="btn medium" onClick={toggleModal}>
            <img src={curioParachainWhite} alt="" />
            Connect wallet
          </button>
        ))}
      {!isParachain &&
        !isTon &&
        (address ? (
          <IconWithName
            icon={
              <Jazzicon
                diameter={24}
                seed={parseInt(address.slice(2, 10), 16)}
              />
            }
            title={shortAddress(address)}
            onClick={() => open()}
          />
        ) : (
          <button className="btn medium" onClick={() => open()}>
            <img src={ethereumWhite} alt="" /> Connect wallet
          </button>
        ))}
      {isTon &&
        (tonAddress ? (
          <IconWithName
            icon={
              <Jazzicon
                diameter={24}
                seed={parseInt(tonAddress.slice(2, 10), 16)}
              />
            }
            title={shortAddress(tonAddress)}
            onClick={() => tonConnectUI.disconnect()}
          />
        ) : (
          <button
            className="btn medium"
            onClick={() => tonConnectUI.openModal()}
          >
            <img src={ton} alt="" /> Connect wallet
          </button>
        ))}
      {isShowing && <ConnectWalletModal toggleModal={toggleModal} />}
    </>
  );
};
