import React from "react";
import {
  PoolsPosition,
  PoolsEthPosition,
  TonPoolsPositions,
} from "../../modules/Pools";
import { IPage } from "../../common/interfaces/IPage";
import { PoolsScreen } from "../../components/PoolsScreen";
import { useNetworkContext } from "../../modules/Network";
import Skeleton from "react-loading-skeleton";

export const PoolsPage = ({ isParachain }: IPage) => {
  const { core, isTon } = useNetworkContext();
  return (
    <>
      <PoolsScreen />
      <p className="p1 semi">Your positions</p>
      {isParachain ? (
        <PoolsPosition />
      ) : isTon ? (
        <TonPoolsPositions />
      ) : core ? (
        <PoolsEthPosition />
      ) : (
        <Skeleton height={64} count={3} />
      )}
    </>
  );
};
