import React from "react";
import { SwapIcon, InfoRow } from "@curiodao/capital-dex-ui-kit";
import { Trade } from "@uniswap/sdk";
import { IUniswapSetting } from "../../interfaces/IUniswapSettings";
import { IToken } from "@curiodao/capital-dex-sdk";

export const SwapInfoCard = ({
  tokens,
  trade,
  uniswapSettings,
}: {
  tokens: (IToken | undefined)[];
  trade: Trade;
  uniswapSettings: IUniswapSetting;
}) => {
  return (
    <div className="card bordered">
      <InfoRow
        title="Rates"
        value={
          <>
            1 {tokens[0]?.symbol} = {trade.route.midPrice.toSignificant(4)}{" "}
            {tokens[1]?.symbol} <SwapIcon />
          </>
        }
      />
      <InfoRow
        title="Minimum received"
        info="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
        value={`${trade.minimumAmountOut(uniswapSettings.slippage).toSignificant(4)} ${tokens[1]?.symbol}`}
      />
      <InfoRow
        title="Price impact"
        info="The difference between the market price and estimated price due to trade size."
        // TODO does it need * 100?
        value={`${trade.priceImpact.toSignificant(4)}%`}
      />
      <InfoRow
        title="Liquidity provider fee"
        info="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive."
        value={`${parseFloat(trade.inputAmount.toSignificant(6)) * 0.003} ${tokens[0]?.symbol}`}
      />
    </div>
  );
};
