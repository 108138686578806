import { useState, useEffect } from "react";
import { IPool } from "../../../Pools/_parachain/interfaces/pool";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { ApiPromise } from "@polkadot/api";
import { useBlock } from "../../../../common/hooks/useBlock";
import { IToken } from "@curiodao/capital-dex-sdk";
import { getTokenInfoBySymbol } from "../../../Tokens";

export const useHarvestReward = (
  api: ApiPromise | undefined,
  account: Account,
  pool: IPool | undefined,
  rewardTokens: string[],
) => {
  const [rewards, setRewards] = useState<{ token: IToken; value: Web3Num }[]>(
    [],
  );
  const block = useBlock();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (pool && account && api) {
        setLoading(true);
        const farms = new FarmsService(api);
        const tokens = rewardTokens
          .map((t) => getTokenInfoBySymbol(t))
          .filter((f) => !!f);

        const rewards = await Promise.all(
          (tokens as IToken[]).map(async (token) => {
            const rewards = await farms.getHarvestRewards(
              account,
              pool.dexShare,
              token.symbol,
            );
            return {
              token,
              value: rewards,
            };
          }),
        );
        setRewards(rewards);
        setLoading(false);
      }
    })();
  }, [api, account, pool, rewardTokens, block]);

  return { rewards, loading };
};
