import { Header } from "../Header";
import { Footer } from "../Footer";
import classes from "./Layout.module.css";
import classNames from "classnames";
import { Outlet } from "react-router-dom";
import { Banner } from "../../components/Banner";
import { useInitNetwork } from "../Network/context/Network/useInitNetwork";
import { ChangeNetworkModal, Modal } from "@curiodao/capital-dex-ui-kit";

export const Layout = () => {
  const { errorNetwork } = useInitNetwork();

  return (
    <div className={classNames("bg", classes.Layout)}>
      <Banner />
      <Header />
      <main className="container">
        <Outlet />
      </main>
      <Footer />
      {errorNetwork && (
        <Modal title="Network error">
          <ChangeNetworkModal networkName={errorNetwork} tokenSymbol={""} />
        </Modal>
      )}
    </div>
  );
};
