import React from "react";
import classes from "./Hero.module.css";
import swapImage from "../../assets/swap-image.png";
import firstCoin from "../../assets/coin-1.svg";
import seconsCoin from "../../assets/coin-2.svg";
import ethCoin from "../../assets/coin-eth.svg";
import cgtCoin from "../../assets/coin-cgt.svg";
import { Link } from "react-router-dom";

export const HeroSection = (): JSX.Element => {
  return (
    <section className={classes.Section}>
      <div className={classes.Content}>
        <div className={classes.TopBlock}>
          <h1 className="h1">DeFi Lego for RWAs</h1>
          <p className="p1">Powered by Curio Chain</p>
          <Link to="/swap" className="btn medium">
            Launch app
          </Link>
        </div>
        <div className={classes.ImageBlock}>
          <img src={swapImage} alt="Swap sample" />
        </div>
        <img src={firstCoin} alt="Coin" className={classes.FirstCoin} />
        <img src={seconsCoin} alt="Coin" className={classes.SecondCoin} />
        <img src={ethCoin} alt="Coin" className={classes.ThirdCoin} />
        <img src={cgtCoin} alt="Coin" className={classes.FourthCoin} />
      </div>
    </section>
  );
};
