import { useEffect, useState } from "react";
import { PoolsService } from "../services/pools.service";
import { IToken } from "@curiodao/capital-dex-sdk";
import { getTokenByAddress } from "../../../../libs";
import { tonDexClient } from "../../../../config/clients";
import { useQuery } from "@tanstack/react-query";
import { JettonsService } from "../../../Tokens";
import { IPosition } from "../interfaces/IPosition";

export const usePositions = (accountId: string, tokenList: IToken[]) => {
  const [pools, setPools] = useState<IPosition[]>([]);
  const [loading, setLoading] = useState(true);
  const { data: balances } = useQuery({
    queryKey: ["fetchBalances", accountId],
    queryFn: () => new JettonsService(tonDexClient).fetchBalances(accountId),
  });

  useEffect(() => {
    if (accountId && balances) {
      setLoading(true);
      const poolsService = new PoolsService();
      poolsService.getPositions(balances, tonDexClient).then((res) => {
        if (res) {
          const positions: IPosition[] = [];
          res.map((position) => {
            const factor =
              Number(position.lp_balance) / position.lp_total_supply;
            positions.push({
              token0: getTokenByAddress(position.token0_address, tokenList),
              token1: getTokenByAddress(position.token1_address, tokenList),
              address: position.address,
              token0Amount: (Number(position.reserve0) * factor).toString(),
              token1Amount: (Number(position.reserve1) * factor).toString(),
              totalSupply: position.lp_total_supply.toString(),
              userShare: position.lp_balance || "0",
              percent: factor * 100,
            });
          });
          console.log(positions);
          setPools(positions);
          setLoading(false);
        }
      });
    }
  }, [accountId, balances, tokenList]);

  return { pools, loading };
};
